import { ProjectOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetch } from '../../../hooks/useFetch/useFetch';
import TableLZM from '../../components/design/table/TableLZM';
import { ButtonLZ } from '../../components/design/Buttons/ButtonLZ';
import { EmailProjectDrawer } from './Modal/EmailProjectDrawer';

export const MonitoringReviews = () => {

  const { t } = useTranslation();
  
  const [updateData, setUpdateData] = useState(false);
  
  const [emailProject, setEmailProject] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [emailProjectDrawer, setEmailProjectDrawer] = useState(null);
  
  useFetch(`${process.env.REACT_APP_API}/tech/services/projects`, {}, [updateData], (data) => {

		setEmailProject(data);
    setIsLoading(isLoading);
	});

  const columns = [        
    {
      title: 'Proyecto',
      key: 'project',
      dataIndex: 'project',
      render: (value: boolean, record: any) => (
          <ButtonLZ size='small' onClick={() => {setEmailProjectDrawer(record); setUpdateData(!updateData)}}>
            <ProjectOutlined/> {record.email}         
          </ButtonLZ>                       
      )
    },
    {
      title: 'email',
      key: 'email',
      dataIndex: 'email'
    },
    {
      title: 'googleEmail',
      key: 'googleEmail',
      dataIndex: 'googleEmail'
    }
	]
  
  return (
    <>
      <Card className='main-card-content'>
        <h1 className='title-card'>Reseñas</h1>        

        <TableLZM columns={columns} dataSource={emailProject} rowKey={'name'} loading={isLoading} />        
        {emailProjectDrawer && <EmailProjectDrawer emailProject={emailProjectDrawer} onFinish={() => {setUpdateData(!updateData)}} onClose={() => {setEmailProjectDrawer(null)}}/>}
      </Card>      
    </>
  )
}
