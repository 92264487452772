import { Tabs } from 'antd';
import InfoTab from './InfoTab';
import ContactTab from './ContactTab';
import PhotosTab from './PhotosTab';
import { t } from 'i18next';

import "./styleTabs.css"
import { CategoryTab } from './CategoryTab';
import { ServiceTab } from './ServiceTab';
import { RegularScheduleTab } from './RegularScheduleTab';
import { SpecialScheduleTab } from './SpecialScheduleTab';

export const LocationDrawerTabs = ({ location, mediaItems, setPostImagesVisible }: any) => {    

    return (
        <div className='tab-container'>
            
            <Tabs defaultActiveKey="1" type="line">                

                <Tabs.TabPane
                    tab={
                        <span className='title-tabPane'>
                            {t('dictionary.info')}
                        </span>
                    }
                    key="1">
                    <InfoTab location={location} locationsIds={location.name.split()}/>
                </Tabs.TabPane>

                <Tabs.TabPane
                    tab={
                        <span className='title-tabPane'>
                            {t('dictionary.contact')}
                        </span>
                    }
                    key="2">
                    <ContactTab location={location} locationsIds={location.name.split()}/>
                </Tabs.TabPane>

                <Tabs.TabPane
                    tab={
                        <span className='title-tabPane'>
                            {t('dictionary.category')}
                        </span>
                    }
                    key="3">
                    <CategoryTab location={location} locationsIds={location.name.split()} />
                </Tabs.TabPane>
                
                <Tabs.TabPane
                    tab={
                        <span className='title-tabPane'>
                             {t('dictionary.services')}
                        </span>
                    }
                    key="4">
                    <ServiceTab location={location} locationsIds={location.name.split()} />
                </Tabs.TabPane>

                <Tabs.TabPane
                    tab={
                        <span className='title-tabPane'
                        >
                            {t('dictionary.schedule')}
                        </span>
                    }
                    key="5"
                >
                    <RegularScheduleTab location={location} />

                </Tabs.TabPane>

                <Tabs.TabPane
                    tab={
                        <span className='title-tabPane'
                        >
                            Festivos
                        </span>
                    }
                    key="6"
                >
                    <SpecialScheduleTab location={location} />

                </Tabs.TabPane>

                <Tabs.TabPane
                    tab={
                        <span className='title-tabPane'>
                            Fotos
                        </span>
                    }

                    key="7"
                >
                    <PhotosTab mediaItems={mediaItems} setPostImagesVisible={setPostImagesVisible} />

                </Tabs.TabPane>
            </Tabs>
        </div>
    );
};
