import { Card, Col, Divider, Rate, Row, Tag } from "antd";
import { Column, RingProgress } from "@ant-design/charts";
import { ShopOutlined, StarFilled } from "@ant-design/icons";
import MathUtils from "../../../../common/utils/MathUtils";
import { useTranslation } from "react-i18next";
import { getColor } from "../../../../common/utils/ColorRate";
import moment from "moment";

export const BlockReviewsGlobal = (props: {data: any}) => {

    const { t } = useTranslation();
            
    const categoryNames: any = {
        'Contestadas': 'reviews.page.global.cards.replied',
        'Sin contestar': 'reviews.page.global.cards.notReplied',
    };

    const config: any = {
        isStack: true,
        xField: 'x',
        yField: 'value',
        seriesField: 'category',
        color: ['#2DA485', '#FFC64E'],
        label: {        
            position: 'bottom',
            style: {
              fill: 'black',
            },
          },
        //annotations: true,
        xAxis: {
			label: {
				formatter: (text: any) => {
					return moment(text, 'YYYY-MM').format('MMM YYYY');					
				},
				autoHide: true,
				autoRotate: true,
			},
		},
        meta: {
            category: {
                formatter: (value: string | number) => {
                    const translationKey = categoryNames[value];
                    return translationKey ? t(translationKey) : value;
                },
            }
        }
    };

    const configArea: any = {
        xField: 'x',
        yField: 'value',
        xAxis: {
            //range: [0, 1],
            label: {
				formatter: (text: any) => {
					return moment(text, 'YYYY-MM').format('MMM YYYY');					
				},
				autoHide: true,
				autoRotate: true,
			},
        },        
        label: {        
            position: 'middle',
            style: {
              fill: 'black'
            },
            formatter: (score: any) => {                
                return score.value.toFixed(1);
              },
        },
        color: '#FFC64E',       
        meta: {
            value: {
                alias: 'Puntuación',
            }  
        }        
    };

    return (
        <>
            <Row gutter={36} style={{ marginBottom: '20px' }}>
                <Col span={12}>
                    <Card bodyStyle={{ backgroundColor: 'white', borderRadius: '25px', height: '327px' }} style={{ border: 'none' }} >
                        <div className="title-charts">
                            <h1 >{t('reviews.page.global.cards.reviewDistribution')}</h1>
                        </div>
                        <div className="review-distribution-content">
                            {props.data.reviewsDistribution.map((review: any) => {
                                return <div style={{
                                    display: 'flex', alignItems: 'center',
                                    gap: '10px',
                                    justifyContent:'space-between'
                                     }}>
                                        <div>
                                            <Rate allowHalf disabled value={review.stars}/>
                                        </div> 
                                        <div style={{width:'70%', display:'flex', justifyContent:'space-between'}}>
                                            <div style={{
                                                backgroundColor: getColor(review.stars), 
                                                borderRadius: '0px 8px 8px 0px',
                                                height: '17px',
                                                width: `${100 * review.percentatge}%`,
                                            }}>
                                        </div>    
                                        <b>{MathUtils.round(100 * review.percentatge, 0)}%</b>                                                                                                                
                                    </div>                                    
                                </div>
                            })
                            }
                        </div>
                    </Card>
                </Col>
                <Col span={12}>
                    <Card bodyStyle={{ backgroundColor: 'white', borderRadius: '25px', height: '327px' }} style={{ border: 'none' }}>
                        <div className="title-charts">
                            <h1 >{t('reviews.page.global.cards.reviews')}</h1>
                        </div>
                        <div style={{ padding: '25px 0px 25px 25px' }}>
                            <Column data={props.data.numberOfReviewsByMonth} height={200} {...config} />
                        </div>
                    </Card>
                </Col>

            </Row>
            <Row gutter={36}>

                <Col span={12}>
                    {/* <Card bodyStyle={{ backgroundColor: 'white', borderRadius: '25px' }} style={{ border: 'none' }} >
                        <div>
                            Distribución de reseñas
                        </div>
                    </Card> */}

                </Col>
                <Col span={24}>
                    <Card bodyStyle={{ backgroundColor: 'white', borderRadius: '25px', height: '327px' }} style={{ border: 'none' }}>
                        <div className="title-charts">
                            <h1 >{t('reviews.page.global.cards.reputationHistory')}</h1>
                        </div>
                        <div style={{ padding: '25px' }}>
                            <Column data={props.data.scoreByMonth} height={200} {...configArea} />
                        </div>
                    </Card>
                </Col>
            </Row>
        </>
    )
}