import { useState, useEffect, useCallback } from 'react';

import GoogleMapReact, { fitBounds } from 'google-map-react';
import { BorderOuterOutlined, CalendarOutlined, EnvironmentFilled, MailOutlined, SearchOutlined, TrophyFilled } from "@ant-design/icons";
import { useFetch } from '../../../hooks/useFetch/useFetch';
import { ModalInfo } from './modal/ModalInfo';
import { Marker } from './modal/Marker';
import { ButtonLZ } from '../../components/design/Buttons/ButtonLZ';
import { Badge, Calendar, message } from 'antd';
import { authRequest } from '../../../common/request/authRequest';
import { getProjectStorage } from '../../../store/storageContext';
import { useHistory, useLocation } from 'react-router-dom';
import { GeogridDrawer } from './modal/GeogridDrawer';
import { display } from 'html2canvas/dist/types/css/property-descriptors/display';
import moment from 'moment';
import { useAuth } from '../../../store/AuthContext/AuthContext';

export const MapCompetitors = (props: {locationId: string, keyword: string }) => {

    const [updateData, setUpdateData] = useState(false);
    const [geoGrid, setGeoGrid] = useState<any>([]);
    const [zoom, setZoom] = useState(10);
    const [center, setCenter] = useState({ lat: 0, lng: 0 });
    const [openModal, setOpenModal] = useState({ open: false, idLoc: 0 })
    const [pos, setPos] = useState<any>()    
    const [geogridDrawer, setGeogridDrawer] = useState(false);
    const { logOut } = useAuth();

    useEffect(() => {
        
        authRequest(
          `${process.env.REACT_APP_API}/projects/${getProjectStorage()}/geogrid/${props.locationId}?keyword=${props.keyword}`,
            { method: 'GET' }
            )
            .then((data) => {
               
                setGeoGrid(data.geoGrid);
                let allPlaces = data.geoGrid?.allPlacesStats;                

                if (allPlaces?.length > 0) {
                    // Inicializa las coordenadas de los límites noroeste y sureste
                    let minLat = allPlaces[0]?.lat;
                    let maxLat = allPlaces[0]?.lat;
                    let minLng = allPlaces[0]?.lng;
                    let maxLng = allPlaces[0]?.lng;
        
                    allPlaces.map((marker: { lat: number; lng: number; }) => {
                        minLat = Math.min(minLat, marker.lat);
                        maxLat = Math.max(maxLat, marker.lat);
                        minLng = Math.min(minLng, marker.lng);
                        maxLng = Math.max(maxLng, marker.lng);
                    });
        
                    //Obtiene los bounds para posicionar el mapa
        
                    const neswBounds: any = {
                        nw: { lat: minLat, lng: minLng },
                        se: { lat: maxLat, lng: maxLng }
                    };
        
                    const size = {
                        width: 500, 
                        height: 500, 
                    };
        
                    const { center, zoom } = fitBounds(neswBounds, size);
        
                    setCenter(center)
                    setZoom(zoom)
                }
            })
            .catch((error) => {
                if (error && error.status === 401) {
                    if (error.data.message === 'Google') {
                      //history.push('/vincular', { from: location });
                    } else {
                        message.error('Token expirado')
                        logOut();
                    }
                }
            });            
        }, [updateData]);              


    const handleMarkerClick = (url: string) => {           
        window.open(url, '_blank', 'noopener,noreferrer');
    }

    const handleZoomAnimationStart = useCallback((newZoom) => {
        setZoom(newZoom);        
      }, []); 

      const handleZoomAnimationEnd = useCallback((newZoom) => {
        setZoom(newZoom);        
      }, []); 
    
    return (
        <>  
            {geoGrid && 
                <div style={{display:'flex', justifyContent:'space-between'}}>
                    
                    <h2  style={{color: 'teal'}}><CalendarOutlined style={{ fontSize: '26px', fontWeight:800, color: 'teal'}} /> {moment(geoGrid.created).format('lll')}</h2>   

                    <ButtonLZ onClick={() => {setGeogridDrawer(true)}}>
                        <SearchOutlined /> Ver detalle         
                    </ButtonLZ>   
                </div>
            }
            <div style={{display:'flex', justifyContent:'flex-end'}}>
                <div style={{ width: '600px', height: '600px', borderRadius: '25px', overflow: 'hidden', position: 'relative' }}>                            
                    {geoGrid ?
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: "AIzaSyCtmjrbwFhUPNP-LfETh_aAKaFuZJhwDm4" }}
                            defaultCenter={{ lat: 0, lng: 0 }}
                            center={center}
                            zoom={zoom}
                            onZoomAnimationStart={handleZoomAnimationStart}
                            onZoomAnimationEnd={handleZoomAnimationEnd}                    
                            yesIWantToUseGoogleMapApiInternals
                            //options={{disableDefaultUI: true, gestureHandling: 'none'}}
                            //onGoogleApiLoaded={({ map, maps }: any) => handleApiLoaded(map, maps)}
                        >
                            {geoGrid?.allPlacesStats?.map((pos: any, index: any) => (
                                <Marker
                                    key={index}                            
                                    lat={pos.lat}
                                    lng={pos.lng}
                                    location={pos}                            
                                    zoom={zoom}
                                    onClick={() => handleMarkerClick(pos.googleMapsUri)}
                                />
                            ))}

                            {/* a cualquier componente dentro de GoogleMapReact se le tiene que pasar lat y lng para que lo posicione en el mapa */}
                            {openModal.open &&
                                <ModalInfo                            
                                    lat={pos?.lat}
                                    lng={pos?.lng}
                                    pos={pos}
                                    openModal={openModal}
                                    setOpenModal={setOpenModal} />}

                        </GoogleMapReact>
                        :
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'100%'}}>
                            <div className="no-row-select">								
                                <ButtonLZ onClick={() => {setGeogridDrawer(true)}}>
                                    <BorderOuterOutlined /> Empezar
                                </ButtonLZ>  
                                <br/>
                                <h1>Aún no has analizado esta keyword</h1>
                                <span>Pulsa el botón para empezar</span>
                            </div>
                        </div>
                    }
                </div>
            </div>
            {geogridDrawer && <GeogridDrawer locationId={props.locationId} keyword={props.keyword} onFinish={() => {setGeogridDrawer(false);setUpdateData(!updateData);}} onChange={() => {setUpdateData(!updateData);}}/>}										
        </>
    );
}
