import { Card, Collapse, Tabs, Typography, Modal, Tag, Input, Button, Progress, Tooltip } from "antd"
import { ButtonLZ } from "../../../components/design/Buttons/ButtonLZ"
import { useState } from "react";
import { useFetch } from "../../../../hooks/useFetch/useFetch";
import { useAuth } from "../../../../store/AuthContext/AuthContext";
import { useTranslation } from "react-i18next";
import { EditOutlined, DeleteOutlined, SearchOutlined, KeyOutlined, GlobalOutlined, ShopOutlined, FolderOpenOutlined, PicCenterOutlined, PushpinOutlined, PushpinFilled, FolderOpenFilled, ShopFilled, FolderFilled } from "@ant-design/icons";
import { authFetch } from "../../../../common/request/authFetch";
import { getProjectStorage } from "../../../../store/storageContext";
import { Location, LocationCardCompacted } from "../../../components/model/business/FieldsGroup";
import TableLZM from "../../../components/design/table/TableLZM";
import { MapsBusiness } from "../../home/MapBusiness";
import { MapCompetitors } from "../../home/MapCompetitors";
import { Terms } from "../../menuUsers/keywords/entity/TermUtils";
import geoEmpty from '../../../../assets/geo_empty_component.png'
import { CaretIncrement } from "../../../../common/utils/Increment";
import { DOT_COLOR } from "../../home/modal/MarkerGeoGrid";

const { Paragraph } = Typography;

export const LocalSEO = () => {

    const [locations, setLocations] = useState(false);
    //const [updateData, setUpdateData] = useState(false);
    //const [keywordsDrawer, setKeywordsDrawer] = useState(null);
	const [count, setCount] = useState(0);
	const [competitorsMapDrawer, setCompetitorsMapDrawer] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(true);
    const { groupAccount } = useAuth();
    const { t } = useTranslation();
    
    useFetch(`${process.env.REACT_APP_API}/projects/` + getProjectStorage() + '/keywords/' + groupAccount + '/locations',
		{},
		[],
		(data) => {
			setLocations(data);        
            setIsLoading(false);        
		}
	);

	const handleKeywordClick = (locationId: string, keyword: string) => {
		
		//setCompetitorsMapDrawer(null);
		//debugger;
		setCompetitorsMapDrawer({locationId: locationId, keyword: keyword});
		setCount(count + 1);
	};

	const isActive = (locationId: string, keyword: string) => {
		return competitorsMapDrawer?.locationId === locationId && competitorsMapDrawer?.keyword === keyword;
	};	

    const SEO_COLUMNS: any = [		
		{
			title: 'Establecimiento',
			key: 'location',			
			width: '300px',
			//sorter: (a: any, b: any) => a.location.locality?.localeCompare(b.location.locality),
			filterDropdown: ({
			  setSelectedKeys,
			  selectedKeys,
			  confirm,			  
			}: any) => {
			  return (
				<Input
				  autoFocus
				  //placeholder="Filtro"
				  value={selectedKeys[0]}
				  onChange={(e: any) => {
					setSelectedKeys(e.target.value ? [e.target.value] : []);
					confirm({ closeDropdown: false });
				  }}
				  onPressEnter={() => {
					confirm();
				  }}
				  onBlur={() => {
					confirm();
				  }}
				></Input>
			  );
			},
			filterIcon: () => {
			  return <SearchOutlined />;
			},
			onFilter: (value: any, record: any) => {
			  return record.location.title?.toLowerCase().includes(value?.toLowerCase()) 
			  || record.location?.administrativeArea?.toLowerCase().includes(value?.toLowerCase())
			  || record.location?.locality?.toLowerCase().includes(value?.toLowerCase());
			},
			render: (text: string, record: any) => (				
				<LocationCardCompacted location={record.location}/>														
			)			
		},
		{
			title: 'Visibilidad por keyword',
			key: 'terms',			
			render: (text: string, record: any) => (
				<div>													                                   
					{ record.domainTerms?.map((term: any, index: number) => {
                            return 	<div style={{cursor: 'pointer', display:'flex', justifyContent:'space-between'}}>										
							<Tag icon={<GlobalOutlined />} onClick={() => {handleKeywordClick(record.locationId, term.name)}} style={{cursor: 'pointer', padding:'2px 6px', margin:'5px', fontSize:'12px', background:isActive(record.locationId, term.name) ? 'dodgerblue' : 'white', color: isActive(record.locationId, term.name) ? 'white' : 'dodgerblue', border:'solid 1px dodgerblue', borderRadius:'7px'}} color="white"> 
								{term.name}
							</Tag>
							{term.created ? 
								<Tooltip title={
												term.popularityPosAVG === 0 ? 
													<div>	
														<div style={{marginTop:'15px'}}><b>BÚSQUEDAS EN GOOGLE MAPS</b></div>
														<div style={{marginTop:'8px', color:'lightgrey'}}><small>No eres relevante para esta keyword, no apareces en el TOP 20 de ubicaciones por distancia</small></div>
													</div>																
													:																
													<div style={{fontSize:'14px', marginTop:'5px'}}>
														<div style={{marginTop:'15px'}}><b>BÚSQUEDAS EN GOOGLE MAPS</b></div>
														<div style={{marginTop:'15px'}}><b>Posición media:</b></div>
														<Tag style={{height:'20px', margin:'0px 20px', borderRadius:'10px'}} color={getColor(term.distanceDiff)}>Posición {term.popularityPosAVG.toFixed(1)} <CaretIncrement value={term.distanceDiff} numberOfDecimals={1} color={'white'}/></Tag>  																	
														<div style={{marginTop:'8px', color:'lightgrey'}}><small>Entre paréntesis la variación del ranking de Google respecto a la posición que tendría por distancia</small></div>																	
														<div style={{marginTop:'15px'}}><b>Presencia en Localpack: </b></div>
														<div style={{marginLeft:'15px'}}>- Presencia {(100 * term.localPackPercent).toFixed(0)}%</div>
														<div style={{marginLeft:'15px'}}>- Alcance: {term.localPackMaxDistance.toFixed(1)} mts.</div>
														<div style={{marginTop:'8px', color:'lightgrey'}}><small>Porcentaje de puntos del geogrid en el que está en TOP3 y distancia en la que deja de estarlo</small></div>																	
													</div>																
												}>
									<div style={{display:'flex', width:'50%', justifyContent:'space-between', alignItems:'center'}}>												
										<Tag style={{height:'20px', width: '300px', margin:'0px 20px', borderRadius:'10px'}} color={getColor(term.distanceDiff)}>
											{term.popularityPosAVG > 0 ? <>Pos. {term.popularityPosAVG.toFixed(1)} <CaretIncrement value={term.distanceDiff} numberOfDecimals={1} color={'white'}/></> : <>No Relevante</>}
										</Tag>  
										<Progress size="small" strokeColor="teal" percent={Math.round(100 * term.localPackPercent)} />														
									</div>
								</Tooltip>												
								:
								<small style={{color:'LightSkyBlue', alignContent:'center'}}><i>Sin analizar</i></small>
							}
						</div>                                                                                                                                             
                        })
                    }        
					{ record.groupTerms?.map((term: any) => {
                            return 	<div style={{cursor: 'pointer', display:'flex', justifyContent:'space-between'}}>										
							<Tag icon={<FolderOpenFilled />} onClick={() => {handleKeywordClick(record.locationId, term.name)}} style={{cursor: 'pointer', padding:'2px 6px', margin:'5px', fontSize:'12px', background:isActive(record.locationId, term.name) ? 'dodgerblue' : 'white', color: isActive(record.locationId, term.name) ? 'white' : 'dodgerblue', border:'solid 1px dodgerblue', borderRadius:'7px'}} color="white"> 
								{term.name}
							</Tag>
							{term.created ? 
								<Tooltip title={
												term.popularityPosAVG === 0 ? 
													<div>	
														<div style={{marginTop:'15px'}}><b>BÚSQUEDAS EN GOOGLE MAPS</b></div>
														<div style={{marginTop:'8px', color:'lightgrey'}}><small>No eres relevante para esta keyword, no apareces en el TOP 20 de ubicaciones por distancia</small></div>
													</div>																
													:																
													<div style={{fontSize:'14px', marginTop:'5px'}}>
														<div style={{marginTop:'15px'}}><b>BÚSQUEDAS EN GOOGLE MAPS</b></div>
														<div style={{marginTop:'15px'}}><b>Posición media:</b></div>
														<Tag style={{height:'20px', margin:'0px 20px', borderRadius:'10px'}} color={getColor(term.distanceDiff)}>Posición {term.popularityPosAVG.toFixed(1)} <CaretIncrement value={term.distanceDiff} numberOfDecimals={1} color={'white'}/></Tag>  																	
														<div style={{marginTop:'8px', color:'lightgrey'}}><small>Entre paréntesis la variación del ranking de Google respecto a la posición que tendría por distancia</small></div>																	
														<div style={{marginTop:'15px'}}><b>Presencia en Localpack: </b></div>
														<div style={{marginLeft:'15px'}}>- Presencia {(100 * term.localPackPercent).toFixed(0)}%</div>
														<div style={{marginLeft:'15px'}}>- Alcance: {term.localPackMaxDistance.toFixed(1)} mts.</div>
														<div style={{marginTop:'8px', color:'lightgrey'}}><small>Porcentaje de puntos del geogrid en el que está en TOP3 y distancia en la que deja de estarlo</small></div>																	
													</div>																
												}>
									<div style={{display:'flex', width:'50%', justifyContent:'space-between', alignItems:'center'}}>												
										<Tag style={{height:'20px', width: '300px', margin:'0px 20px', borderRadius:'10px'}} color={getColor(term.distanceDiff)}>
											{term.popularityPosAVG > 0 ? <>Pos. {term.popularityPosAVG.toFixed(1)} <CaretIncrement value={term.distanceDiff} numberOfDecimals={1} color={'white'}/></> : <>No Relevante</>}
										</Tag>  
										<Progress size="small" strokeColor="teal" percent={Math.round(100 * term.localPackPercent)} />														
									</div>
								</Tooltip>												
								:
								<small style={{color:'LightSkyBlue', alignContent:'center'}}><i>Sin analizar</i></small>
							}
						</div>                                                                                                                                
                        })
                    }           
					{ record.locationTerms?.map((term: any) => {
                            return 	<div style={{cursor: 'pointer', display:'flex', justifyContent:'space-between'}}>										
										<Tag icon={<ShopFilled />} onClick={() => {handleKeywordClick(record.locationId, term.name)}} style={{cursor: 'pointer', padding:'2px 6px', margin:'5px', fontSize:'12px', background:isActive(record.locationId, term.name) ? 'dodgerblue' : 'white', color: isActive(record.locationId, term.name) ? 'white' : 'dodgerblue', border:'solid 1px dodgerblue', borderRadius:'7px'}} color="white"> 
											{term.name}
										</Tag>
										{term.created ? 
											<Tooltip title={
															term.popularityPosAVG === 0 ? 
																<div>	
																	<div style={{marginTop:'15px'}}><b>BÚSQUEDAS EN GOOGLE MAPS</b></div>
																	<div style={{marginTop:'8px', color:'lightgrey'}}><small>No eres relevante para esta keyword, no apareces en el TOP 20 de ubicaciones por distancia</small></div>
																</div>																
																:																
																<div style={{fontSize:'14px', marginTop:'5px'}}>
																	<div style={{marginTop:'15px'}}><b>BÚSQUEDAS EN GOOGLE MAPS</b></div>
																	<div style={{marginTop:'15px'}}><b>Posición media:</b></div>
																	<Tag style={{height:'20px', margin:'0px 20px', borderRadius:'10px'}} color={getColor(term.distanceDiff)}>Posición {term.popularityPosAVG.toFixed(1)} <CaretIncrement value={term.distanceDiff} numberOfDecimals={1} color={'white'}/></Tag>  																	
																	<div style={{marginTop:'8px', color:'lightgrey'}}><small>Entre paréntesis la variación del ranking de Google respecto a la posición que tendría por distancia</small></div>																	
																	<div style={{marginTop:'15px'}}><b>Presencia en Localpack: </b></div>
																	<div style={{marginLeft:'15px'}}>- Presencia {(100 * term.localPackPercent).toFixed(0)}%</div>
																	<div style={{marginLeft:'15px'}}>- Alcance: {term.localPackMaxDistance.toFixed(1)} mts.</div>
																	<div style={{marginTop:'8px', color:'lightgrey'}}><small>Porcentaje de puntos del geogrid en el que está en TOP3 y distancia en la que deja de estarlo</small></div>																	
																</div>																
															}>
												<div style={{display:'flex', width:'50%', justifyContent:'space-between', alignItems:'center'}}>												
													<Tag style={{height:'20px', width: '300px', margin:'0px 20px', borderRadius:'10px'}} color={getColor(term.distanceDiff)}>
														{term.popularityPosAVG > 0 ? <>Pos. {term.popularityPosAVG.toFixed(1)} <CaretIncrement value={term.distanceDiff} numberOfDecimals={1} color={'white'}/></> : <>No Relevante</>}
													</Tag>  
													<Progress size="small" strokeColor="teal" percent={Math.round(100 * term.localPackPercent)} />														
												</div>
											</Tooltip>												
											:
											<small style={{color:'LightSkyBlue', alignContent:'center'}}><i>Sin analizar</i></small>
										}
									</div>                                                                        
                        })
                    }        												                					                    
				</div>
			)
		}
	]

    return (
        <Card className='main-card-content'>
            <h1 className='title-card'>Competidores <Tag color='purple'>MÓDULO EXTRA: SEO</Tag></h1>
            
			<div style={{display:'flex'}}>
				<div style={{flex: '1 60%', overflowY: 'scroll', height:'80vh'}}>
					<TableLZM
							rowKey='locationName'
							scroll={{ x: true }}
							columns={SEO_COLUMNS}
							dataSource={locations}
							loading={isLoading}
						/>
					</div>
				<div style={{flex: '2 40%', margin:"20px 0 0 20px"}}>
					<div>
					{
						competitorsMapDrawer &&  
							<MapCompetitors key={`${competitorsMapDrawer.locationId}-${competitorsMapDrawer.keyword}`} locationId={competitorsMapDrawer.locationId} keyword={competitorsMapDrawer.keyword}/>
					}
					{
						!competitorsMapDrawer &&				
							<div className="no-row-select">
								<img src={geoEmpty} width='30%'/>								
								<h1 >No tienes ninguna keyword seleccionada</h1>
          						<span>Selecciona alguna para ver el ranking</span>
							</div>
					}
					</div>
				</div>
			</div>
        </Card>		
    )
}


export const getColor = (positionDiff: number) => {

		
	if (positionDiff < -20) {
		return "black";		
	//} else if (positionDiff <= -10) {
	//		return "Crimson";		
	} else if (positionDiff <= -1) {
		return "Crimson";
	} else if (positionDiff == 0) {
		return "SlateGray";
	} else {
		return "SeaGreen";
	}
};
