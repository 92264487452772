import { Card, Col, Rate, Row, Tag } from "antd";
import { useState } from 'react'
import { useAuth } from '../../../../store/AuthContext/AuthContext';
import { Column, Area } from '@ant-design/plots';
import { useFetch } from "../../../../hooks/useFetch/useFetch";
import { getProjectStorage } from "../../../../store/storageContext";
import { getColor } from "../../../../common/utils/ColorRate";
import { DownOutlined, DownloadOutlined, InfoCircleOutlined, LoadingOutlined, StarFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import './ReviewsSummary.css';
import '../../../../styles/Summaries.css';
import MathUtils from "../../../../common/utils/MathUtils";
import { SpinLZ } from "../../../components/design/Spinner/SpinLZ";
import PDFGenerator from "../../../components/model/pdf/GeneratePDF";
import { SCHEDULEDJOB } from "../../../../common/constants/services";
import { Synchronized } from "../../../components/design/Synchronized/Synchronized";
import { useHistory, useLocation } from "react-router-dom";
import { BlockReviewsKpis } from "../blocks/BlockReviewsKpis";
import { BlockReviewsGlobal } from "../blocks/BlockReviewsGlobal";
import { BlockReviewsByLocation } from "../blocks/BlockReviewsByLocation";
import { DownloadCSVModal } from "../../common/modal/DownloadCSVModal";
import { ButtonLZ } from "../../../components/design/Buttons/ButtonLZ";
import { authRequest } from "../../../../common/request/authRequest";
import SelectPeriod from "../../../components/model/selectMonths/SelectPeriod";

export const ReviewsSummary = () => {

    const { groupAccount } = useAuth();
    
    const [reviewsKPIs, setReviewsKPIs] = useState<any>(null);
    const [reviewsData, setReviewsData] = useState<any>();
    const [locationInfo, setLocationInfo] = useState<any>();
    const [openCSVModal, setOpenCSVModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedType, setSelectedType] = useState('ALL');    
    const [period, setPeriod] = useState<any>();
    const [recentPeriod, setRecentPeriod] = useState<any>();

    const { t } = useTranslation();
    const location = useLocation();
        
    let locationId = new URLSearchParams(location.search).get('locationId');
    locationId = locationId != null ? "?locationId=" + locationId : "";
    
    
    useFetch(`${process.env.REACT_APP_API}/projects/` + getProjectStorage() + '/' + groupAccount + '/summary/reviews' + locationId + '?periodOpt=' + selectedType, {}, [],    
        (data: any) => {
            setReviewsKPIs(data.reviewsKPIs);
            setReviewsData(data);
            setIsLoading(false);
            setLocationInfo(data.location);
            setPeriod(data.period);
            setRecentPeriod(data.recentPeriod);
        });

    return (
        <div>
            <Card className='main-card-content' id='element-to-capture'>
        
                <Synchronized service={[SCHEDULEDJOB.GBP_REVIEWS]}/>	

                <div style={{display:'flex', color:'slategray', justifyContent:'space-between', alignItems:'center'}}>
                    <h1 className='title-card' style={{display:'flex', justifyContent:'space'}}>
                        Estadísticas de Reseñas
                    </h1>                                
                    <div style={{marginRight:'20px'}}>
                        <SelectPeriod setSelectedType={setSelectedType}/>
                    </div>
                </div>        

                {isLoading ? <SpinLZ /> : <div className="card-container">
                    {reviewsKPIs && <BlockReviewsKpis reviewsKPIs={reviewsKPIs} period={period} recentPeriod={recentPeriod}/>}                    
                    {reviewsData && <BlockReviewsGlobal data={reviewsData}/>}                    
                    <br/><h1 className='title-card'>Datos agrupados por Local</h1>
                    {reviewsData && <BlockReviewsByLocation data={reviewsData.locationsKpis} showButtons={true}/>}                                        
                </div>
                }
            </Card>
        </div>
    )
}