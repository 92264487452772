import { EditOutlined, MinusCircleOutlined, PlusCircleOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Badge, Button, Card, Table, Tag, message } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetch } from '../../../../hooks/useFetch/useFetch';
import ProjectsAddModal from './ModalAddProject/ProjectsAddModal';
import './Users.scss';
import '../../pages.scss'
import TableLZM from '../../../components/design/table/TableLZM';
import { ButtonLZ } from '../../../components/design/Buttons/ButtonLZ';
import { TableFilter } from '../../../../common/utils/FilterUtils';
import { authRequest } from '../../../../common/request/authRequest';
import { AddCreditsPackModal } from './ModalAddProject/AddCreditsPackModal';
import { AddCreditsNumberModal } from './ModalAddProject/AddCreditsNumberModal';
import { AddCreditsCurrentModal } from './ModalAddProject/AddCreditsCurrentModal';


export const Projects = () => {

  const { t } = useTranslation();
  const [addUserVisible, setAddUserVisible] = useState(false);
  const [updateData, setUpdateData] = useState(false);
	const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [creditsPackDrawer, setCreditsPackDrawer] = useState(null);
  const [creditsNumberDrawer, setCreditsNumberDrawer] = useState(null);
  const [creditsDrawer, setCreditsDrawer] = useState(null);
  const [locationsNumberDrawer, setLocationsNumberDrawer] = useState(null);

  useFetch(`${process.env.REACT_APP_API}/projects`, {}, [updateData], (data) => {

		setProjects(data);
    setIsLoading(isLoading);
	});

  /*const removeCreditsPack = async (projectId: string, creditsPack: string) => {
		    
    const data = {			
			creditsPack: creditsPack
		};		    
  
		await authRequest(`${process.env.REACT_APP_API}/projects/${projectId}/credits`, { method: 'PUT', data: data })
      .then((data) => {
      setUpdateData(!updateData);
      message.success('Pack de créditos borrado');
    }).catch((error) => {
      message.error('Error imprevisto');
    });
	};	*/

  const columns = [        
    {
      title: 'Nombre',
      key: 'project',
      dataIndex: '_id',
      filterDropdown: TableFilter,
      filterIcon: () => {
			  return <SearchOutlined />;
			},
			render: (text: string, record: any) => (				
        <a onClick={()=>{setCreditsPackDrawer(record.project)}}>{record.project?.name}</a>
			),  
      onFilter: (value: any, record: any) => record._id?.group.includes(value),
      sorter: (a: any, b: any) => a._id?.project.localeCompare(b._id?.group),	
    },
    {
      title: 'Paquetes',
      key: 'packages',      
      filterDropdown: TableFilter,
      filterIcon: () => {
			  return <SearchOutlined />;
			},
			render: (text: string, record: any) => (				
        <>
            
            <div>
              {/*<MinusCircleOutlined style={{ margin: '10px' }} onClick={()=>{removeCreditsPack(record.project, key)}}/>*/}
              <Tag>{record.project?.creditsPlan?.name}</Tag>
            </div>            
            <PlusCircleOutlined style={{fontSize:'18px', marginRight: '5px', color: 'grey'}}
              onClick={() => {
                setCreditsPackDrawer(record.project);
            }}/>      
        </>          
			),  
      onFilter: (value: any, record: any) => record._id?.group.includes(value),
      sorter: (a: any, b: any) => a._id?.project.localeCompare(b._id?.group),	
    },
    {
      title: 'Créditos/mes',
      key: 'creditsPerMonth',      
      render: (text: string, record: any) => (				
        <>
            <EditOutlined style={{fontSize:'18px', marginRight: '5px', color: 'grey'}}
              onClick={() => {
                setCreditsNumberDrawer(record.project);
              }}/>      
            {record.project?.creditsPlan?.creditsPerMonth} créditos/mes
        </>          
			),  
    },
    {
      title: 'Créditos',
      key: 'credits',      
      render: (text: string, record: any) => (				
        <>
            <EditOutlined style={{fontSize:'18px', marginRight: '5px', color: 'grey'}}
              onClick={() => {
                setCreditsDrawer(record.project);
              }}/>      
            {record.project?.creditsPlan?.credits} créditos
        </>          
			),  
    },
    {
      title: '#Locales [contrato]',
      key: 'locations',      
      render: (text: string, record: any) => (				
        <>            
        </>          
			),  
    },
    {
      title: '#Locales [Verificados + abiertos]',
      key: 'locationnumberOfLocationss',
      dataIndex: 'numberOfLocations',
      sorter: (a: any, b: any) => b.numberOfLocations - a.numberOfLocations	
    }
	]

  return (
    <>
      <Card className='main-card-content' >
        <h1 className='title-card'>{t('dictionary.businessManagement')}</h1>
        <div className="buttonWrapper" >
          <ButtonLZ color='yellow' onClick={() => setAddUserVisible(true)} type='primary'>
            <PlusOutlined/> {t('dictionary.newBusiness')}
          </ButtonLZ>
        </div>
        <TableLZM columns={columns} dataSource={projects} rowKey={'name'} loading={isLoading} />
        <ProjectsAddModal
          visible={addUserVisible}
          onFinish={() => {
            setAddUserVisible(false);
            setUpdateData(!updateData);
          }}
        />
      </Card>
      {creditsPackDrawer && <AddCreditsPackModal project={creditsPackDrawer} onFinish={() => {setCreditsPackDrawer(null); setUpdateData(!updateData)}}/>}
      {creditsNumberDrawer && <AddCreditsNumberModal project={creditsNumberDrawer} onFinish={() => {setCreditsNumberDrawer(null); setUpdateData(!updateData)}}/>}
      {creditsDrawer && <AddCreditsCurrentModal project={creditsDrawer} onFinish={() => {setCreditsDrawer(null); setUpdateData(!updateData)}}/>}
      
    </>
	)
}
