import { useEffect, useState } from "react";
import { Button, Card, Drawer, Modal, Radio, Skeleton, Switch, Tag, Tooltip, message } from "antd";
import { useTranslation } from "react-i18next";
import { authRequest } from "../../../../../common/request/authRequest";
import { getProjectStorage } from "../../../../../store/storageContext";
import { useAuth } from "../../../../../store/AuthContext/AuthContext";
import GroupAccount from "../../../register/GroupAccount";
import { AddressGroup, LocationTitle } from "../../../../components/model/business/FieldsGroup";
import { ButtonLZ } from "../../../../components/design/Buttons/ButtonLZ";
import { NewKeywordModal } from "./NewKeywordModal";
import { DeleteFilled, DeleteOutlined, FolderOpenOutlined, GlobalOutlined, InfoCircleFilled, InfoCircleOutlined, MinusCircleOutlined, ShopOutlined } from "@ant-design/icons";
import { authFetch } from "../../../../../common/request/authFetch";

export const KeywordsDrawer = (props: { location: any, onFinish: any, onChange: any }) => {    
    
    const [data, setData] = useState<any>({});
    const [updateData, setUpdateData] = useState(false);
    const [openModal, setOpenModal] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    const { t } = useTranslation();
    
    useEffect(() => {
        setIsLoading(true);
        authRequest(
          `${process.env.REACT_APP_API}/projects/${getProjectStorage()}/keywords/${props.location.composeId}`,
          { method: 'GET' }
        )
          .then((data) => {
            
            setData(data);            
            setIsLoading(false);
          })
          .catch(() => {
            message.error(t('view.errors.loadLocation'))
          });            
      }, [updateData]);    
    
      const deleteReply = async (term: string, scope: string): Promise<void> => {

        const data = {
            locationId: props.location.composeId,
            websiteUri: props.location.websiteUri,
            keyword: term,
            scope: scope
        };

        await authFetch(
          `${process.env.REACT_APP_API}/projects/${getProjectStorage()}/keywords/${props.location.composeId}/terms`,
            { method: 'DELETE', data }
        )
            .then((res) => {
                if (res) {
                    message.success('Keyword borrada de forma satisfactoria', 3);
                    setUpdateData(!updateData)
                    props.onChange();
                } else {
                  message.error('Problema al borrar la keyword', 3);
                }
            })
        }    

    return (        
        <Drawer
            placement="right"
            width={'50%'}
            open={true}            
            onClose={props.onFinish}
            bodyStyle={{ background: '#F4F7FA' }}
            title={<h2 style={{color:'slategray'}}><b>Añadir KEYWORDS</b></h2>}>   
            
            { isLoading && <Skeleton/> }
            { !isLoading &&
            <>
              {data?.domain && <Card>
                <h3 style={{color:'black'}}><GlobalOutlined/> {data?.domain}</h3>
                <i style={{color:'Teal'}}>Estas keywords aplican a todos los locales del dominio</i>
              
               { data?.domainTerms && data.domainTerms.length > 0 ?               
                  data.domainTerms?.map((term: any) => {
                      return  <div style={{ display: 'flex', justifyContent:'space-between', alignItems: 'center', margin:'10px 60px' }}>                            
                                <div style={{ display: 'flex', justifyContent:'space-between', alignItems:'initial'}}>
                                  <Tag icon={<GlobalOutlined />} style={{padding:'3px 8px', fontSize:'14px'}} color="Teal"> {term.name}</Tag>
                                </div>                            
                                <div>                                                       
                                  <Button shape='round' size='small' type='link' style={{ color: 'grey' }} 
                                      onClick={() => {
                                            Modal.confirm({
                                                title: 'Confirmas que quieres eliminar esta keyword',
                                                okText: t('dictionary.confirm'),
                                                cancelText: t('dictionary.cancel'),
                                                onOk: () => deleteReply(term.name, 'DOMAIN')
                                            })
                                        }}>
                                    <DeleteOutlined style={{ fontSize: '12px' }} />
                                    <small> Eliminar keyword</small>
                                  </Button>
                                </div>
                              </div>
                    })
                    :
                    <h2 style={{color:'Silver', marginTop:'12px'}}><b>Aún no hay keywords definidas</b></h2>   
                }                         
                <div style={{ display: 'flex', justifyContent:'flex-end', gap: '10px', alignItems: 'center', marginTop: '16px', marginBottom: '16px' }}>
                  <ButtonLZ onClick={() => { setOpenModal('DOMAIN') }} size="small" style={{minWidth:'200px'}}>
                      + keyword de dominio
                  </ButtonLZ>
                </div>       
              </Card>  
              }            
              <br/>

              <Card>
                <h3 style={{color:'black'}}><FolderOpenOutlined/> <b>{data.groupDisplayName}</b></h3>              
                <i style={{color:'Teal'}}>Estas keywords aplican a todos los locales del grupo de ubicaciones</i>
                { data?.groupTerms && data.groupTerms.length > 0 ?               
                  data.groupTerms?.map((term: any) => {
                      return  <div style={{ display: 'flex', justifyContent:'space-between', alignItems: 'center', margin:'10px 60px' }}>                            
                                <div style={{ display: 'flex', justifyContent:'space-between', alignItems:'initial'}}>
                                  <Tag icon={<FolderOpenOutlined />} style={{padding:'3px 8px', fontSize:'14px'}} color="Teal"> {term.name}</Tag>
                                </div>                            
                                <div>                                                       
                                  <Button shape='round' size='small' type='link' style={{ color: 'grey' }} 
                                      onClick={() => {
                                            Modal.confirm({
                                                title: 'Confirmas que quieres eliminar esta keyword',
                                                okText: t('dictionary.confirm'),
                                                cancelText: t('dictionary.cancel'),
                                                onOk: () => deleteReply(term.name, 'GROUP')
                                            })
                                        }}>
                                    <DeleteOutlined style={{ fontSize: '12px' }} />
                                    <small> Eliminar keyword</small>
                                  </Button>
                                </div>
                              </div>
                    })
                    :
                    <h2 style={{color:'Silver', marginTop:'12px'}}><b>Aún no hay keywords definidas</b></h2>   
                }       
                <div style={{ display: 'flex', justifyContent:'flex-end', gap: '10px', alignItems: 'center', marginTop: '16px', marginBottom: '16px' }}>
                  <ButtonLZ onClick={() => { setOpenModal('GROUP') }} size="small" style={{minWidth:'200px'}}>
                      + keyword de grupo
                  </ButtonLZ>
              </div>       
              </Card>
              <br/>
              <Card>
              <h3 style={{color:'black'}}><ShopOutlined/> <b><LocationTitle location={data.location}/></b></h3>
              <i style={{color:'Teal'}}>Estas keywords aplican solo a este local</i>
              
              { data?.locationTerms && data.locationTerms.length > 0 ? 
                data.locationTerms.map((term: any) => {
                  return  <div style={{ display: 'flex', justifyContent:'space-between', alignItems: 'center', margin:'10px 60px' }}>
                    <div style={{ display: 'flex', justifyContent:'space-between', alignItems:'initial'}}>
                    <Tag icon={<ShopOutlined />} style={{padding:'3px 8px', fontSize:'14px'}} color="Teal"> {term.name}</Tag>
                      </div>                                                  
                      <div>                               
                        <Button shape='round' size='small' type='link' style={{ color: 'grey' }} 
                        onClick={() => {
                                  Modal.confirm({
                                      title: 'Confirmas que quieres eliminar esta keyword',
                                      okText: t('dictionary.confirm'),
                                      cancelText: t('dictionary.cancel'),
                                      onOk: () => deleteReply(term.name, 'LOCAL')
                                  })
                              }}>
                          <DeleteOutlined style={{ fontSize: '12px' }} />
                          <small> Eliminar keyword</small>
                        </Button>
                      </div>
                    </div>
                })              
                :
                <h2 style={{color:'Silver', marginTop:'12px'}}><b>Aún no hay keywords definidas</b></h2>   
              }           
            <div style={{ display: 'flex', justifyContent:'flex-end', gap: '10px', alignItems: 'center', marginTop: '16px', marginBottom: '16px' }}>
              <ButtonLZ onClick={() => { setOpenModal('LOCAL') }} size="small" style={{minWidth:'200px'}}>
                  + keyword de local
              </ButtonLZ>
            </div>       
            </Card>
              </>
            }
            {openModal && <NewKeywordModal locationId={data.location.name} websiteUri={data.location.websiteUri} scope={openModal} onFinish={() => { setUpdateData(!updateData); props.onChange(); setOpenModal(null); }} />} 
        </Drawer>        
    );
};