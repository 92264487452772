import { useEffect, useState } from "react";
import { Badge, Card, Collapse, Divider, Drawer, Input, Progress, Select, Tag, Tooltip, message } from "antd";
import { authRequest } from "../../../../common/request/authRequest";
import { getProjectStorage } from "../../../../store/storageContext";
import { ExportOutlined, EyeFilled, PlusCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { authFetch } from "../../../../common/request/authFetch";
import geoEmpty from '../../../../assets/geogrid_missing.png'
import GoogleMapReact, { fitBounds } from 'google-map-react';
import { Marker } from "../../../pages/home/modal/Marker";
import { ModalInfo } from "../../../pages/home/modal/ModalInfo";
import { DOT_COLOR, MarkerGeoGrid } from "../../../pages/home/modal/MarkerGeoGrid";
import { ColorStar } from "../../../../common/utils/ColorStar";
import { CaretIncrement, Increment } from "../../../../common/utils/Increment";
import { LocationCardCompacted } from "../../../components/model/business/FieldsGroup";
import { ButtonLZ } from "../../../components/design/Buttons/ButtonLZ";
import TableLZM from "../../../components/design/table/TableLZM";
import { getColor } from "../../menuInfo/pageSEO/LocalSEO";
import { NewGeogridModal } from "../../menuInfo/pageSEO/NewGeogridModal";
import moment from "moment";
import { useAuth } from "../../../../store/AuthContext/AuthContext";
import { GeoGridHistory } from "./GeoGridHistory";

export const GeogridDrawer = (props: { locationId: string, keyword: string, onFinish: any, onChange: any }) => {    
            
    const [updateData, setUpdateData] = useState(true);
    const [data, setData] = useState<any>();    
    const [history, setHistory] = useState<any>(null);    
    
    const [zoom, setZoom] = useState(10);
    const [center, setCenter] = useState({ lat: 0, lng: 0 });
    const [newGeoGridModal, setNewGeoGridModal] = useState<any>(null);
    
    const [filterGridId, setFilterGridId] = useState<any>('');    

    const { logOut } = useAuth();    

    useEffect(() => {
        setHistory(null);            
        authRequest(
          `${process.env.REACT_APP_API}/projects/${getProjectStorage()}/geogrid/historic/${props.locationId}?keyword=${props.keyword}`,
            { method: 'GET' }
            )
            .then((data) => {      
                setHistory(data);                                                    
            })
            .catch((error) => {
                if (error && error.status === 401) {
                    if (error.data.message === 'Google') {
                      //history.push('/vincular', { from: location });
                    } else {
                        
                    }
                }
            });            
        }, [updateData]); 

    useEffect(() => {
        
        authRequest(
          `${process.env.REACT_APP_API}/projects/${getProjectStorage()}/geogrid/${props.locationId}?keyword=${props.keyword}${filterGridId}`,
            { method: 'GET' }
            )
            .then((data) => {                
                setData(data);        
                
                if (data?.geoGrid?.points.length > 0) {
                    // Inicializa las coordenadas de los límites noroeste y sureste
                    let minLat = data?.geoGrid.points[0]?.lat;
                    let maxLat = data?.geoGrid.points[0]?.lat;
                    let minLng = data?.geoGrid.points[0]?.lng;
                    let maxLng = data?.geoGrid.points[0]?.lng;
        
                    data?.geoGrid.points.map((marker: { lat: number; lng: number; }) => {
                        minLat = Math.min(minLat, marker.lat);
                        maxLat = Math.max(maxLat, marker.lat);
                        minLng = Math.min(minLng, marker.lng);
                        maxLng = Math.max(maxLng, marker.lng);
                    });
        
                    //Obtiene los bounds para posicionar el mapa
        
                    const neswBounds: any = {
                        nw: { lat: minLat, lng: minLng },
                        se: { lat: maxLat, lng: maxLng }
                    };
        
                    const size = {
                        width: 500, 
                        height: 500, 
                    };
        
                    const { center, zoom } = fitBounds(neswBounds, size);
        
                    setCenter(center)
                    setZoom(zoom)
                }
            
            })
            .catch((error) => {
                if (error && error.status === 401) {
                    if (error.data.message === 'Google') {
                      //history.push('/vincular', { from: location });
                    } else {
                        message.error('Token expirado')
                        logOut();
                    }
                }
            });            
        }, [updateData, filterGridId]); 

    return (
        <>
        <Drawer
            placement="right"
            width={'90%'}
            open={true}
            onClose={props.onFinish}
            bodyStyle={{ background: '#F4F7FA' }}            
            headerStyle={{ background: '#F4F7FA' }}
            title={<div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>                
                <div style={{fontSize:'14px'}}>
                    <LocationCardCompacted location={data?.location}/>
                </div>
                <Tag style={{padding:'3px 8px', margin:'5px', fontSize:'14px'}} color='Teal'> {props.keyword}</Tag>
            </div>}>   

            <Card style={{position:'relative',margin:'0px 10px', boxSizing:'border-box', height:'97%', backgroundColor:'transparent', borderRadius:10}}>                                                        

            {data && 
            <>{data.geoGrid ? 
                <div style={{display:'flex', color:'slategray', justifyContent:'space-between'}}>
                    <div style={{ width: '600px', height: '600px', overflow: 'hidden', position: 'relative' }}>                              
                        <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                        {history && 
                            <Select onChange={(value) => setFilterGridId('&id=' + value)} bordered={false} defaultValue={history[0].id} style={{ minWidth: '300px', color:'teal' }}>
                        
                                {history.slice(0, 20).map((grid: any, index: any)=>(
                                    <Select.Option value={grid.id}>
                                        {moment(grid.created).format('LLLL')} {'('}{moment(grid.created).fromNow()}{')'} 
                                    </Select.Option>
                                ))
                                }        
                            </Select>}   
                            <ButtonLZ size='small' type='default' onClick={() => {setNewGeoGridModal({locationId: props.locationId, keyword: props.keyword})}}>
                                Nuevo escaneo
                            </ButtonLZ>        
                        </div>
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: "AIzaSyCtmjrbwFhUPNP-LfETh_aAKaFuZJhwDm4" }}
                            defaultCenter={{ lat: 0, lng: 0 }}
                            center={center}
                            defaultZoom={10}
                            zoom={zoom}
                            //onZoomAnimationStart={handleZoomAnimationStart}
                            //onZoomAnimationEnd={handleZoomAnimationEnd}                    
                            yesIWantToUseGoogleMapApiInternals
                            //options={{disableDefaultUI: true, gestureHandling: 'none'}}
                            //onGoogleApiLoaded={({ map, maps }: any) => handleApiLoaded(map, maps)}
                        >
                            {data?.geoGrid.points.map((point: any, index: any) => (
                                <MarkerGeoGrid
                                    key={index}                            
                                    lat={point.lat}
                                    lng={point.lng}
                                    point={point}                                          
                                    zoom={200}
                                    //onClick={() => handleMarkerClick(index, pos)}
                                />
                            ))}
                        </GoogleMapReact>
                            
                        </div>       
                        <div style={{margin:'0px 20px', overflowY: 'scroll', height:'70vh'}}>
                            <div style={{marginLeft:'10px', color:'teal'}}>Resumen de los negocios principales: Los mejor posicionados y los más cercanos: </div>
                            <TableLZM
                                rowKey='rankPosition'
                                size='small'
                                //title={'tvmlkm sdlfkdf'}
                                //scroll={{ x: true }}
                                pagination={{ pageSize: 100 }}
                                columns={SEO_COLUMNS}
                                dataSource={data.geoGrid.allPlacesStats}
                                //loading={isLoading}
                            />
                        </div>
                    </div>
                    :                
                    <div className="no-row-select">
                        <img src={geoEmpty} width='50%'/>								
                        <h1>Crea tu primer geogrid</h1>                    
                        <span>Comprueba tu presencia en el localpack alrededor del negocio</span>
                        <ButtonLZ size='small' onClick={() => {setNewGeoGridModal({locationId: props.locationId, keyword: props.keyword})}}>
                            Nuevo escaneo
                        </ButtonLZ>  
                    </div>
}               </>
                }
            </Card>            
        </Drawer>    
        { newGeoGridModal && 
        <NewGeogridModal 
            locationId={newGeoGridModal.locationId} 
            keyword={newGeoGridModal.keyword} 
            onFinish={() => {setNewGeoGridModal(null); setUpdateData(!updateData)}}
            onClose={() => {setNewGeoGridModal(null);}}/>}
        </>
    );
};

const SEO_COLUMNS: any = [		
    {
        title: '',
        dataIndex: 'rankPosition',
        render: (text: string, record: any) => (				
            <div style={{ backgroundColor: DOT_COLOR[record.rankPosition], color:'white', height: '30px', width: '30px', borderRadius: '50%', border:'solid 2px white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {record.rankPosition}                    
            </div>
        )	
    },
    {
        title: 'Posición Media',
        dataIndex: 'positionDistance',
        render: (text: string, record: any) => (
            <>
                <div><small><b>{record.popularityPosAVG < 21 ? record.popularityPosAVG.toFixed(1) : '> 20'}</b> por popularidad</small></div>
                <div style={{color:'Teal'}}><small><b>{record.distancePosAVG < 21 ? record.distancePosAVG.toFixed(1): '> 20'}</b> por distancia</small></div>
                
            </>
        )	
    },
    {
        title: 'Posición',
        width: '100px',
        dataIndex: 'popularityPosAVG',
        render: (text: string, record: any) => (
            <Tag style={{borderRadius:'10px', fontSize:'10px'}} color={getColor(record.distanceDiff)}><CaretIncrement value={record.distanceDiff} numberOfDecimals={1} color={'white'}/></Tag>              
        )	
    },
    {
        title: 'Local',
        dataIndex: 'name',
        width: '200px',
        render: (text: string, record: any) => (				
            <>
            {record.reference?
                <Tag style={{color:'blue', borderRadius:'20px'}} color="dodgerblue"><a href={record.googleMapsUri} target='blank'> {record.name}</a></Tag>
                :
                <a href={record.googleMapsUri} target='blank'> {record.name}</a>
            }
            </>
        )	
    },
    {
        title: '%Localpack',
        dataIndex: 'localPackPercent',
        align: 'center',
        render: (text: string, record: any) => (
            <>
                {record.localPackPercent > 0 ?
                    <>
                        <Progress size="small" strokeColor="teal" percent={Math.round(100 * record.localPackPercent)} />                
                        <small style={{color:'Teal'}}>a {record.localPackMaxDistance.toFixed(1)} mts.</small>
                    </>
                    :
                    <small style={{color:'LightSkyBlue'}}>Sin presencia</small>
                }  
            </>
        )	
    },
    {
        title: 'Reseñas',
        dataIndex: 'localPackPercent',
        align: 'center',
        render: (text: string, record: any) => (
            <div style={{color:'grey'}}>
                <div>{Math.round(10 * record.rating) / 10}</div>
                <small>{record.userRatingsTotal} reseñas</small>
            </div>
        )	
    },
    {
        title: 'Web',
        dataIndex: 'websiteUri',
        align: 'center',
        render: (text: string, record: any) => (
            <>{record.websiteUri && <a href={record.websiteUri} target='blank'>Web</a>}</>				
        )	
    }           
]




