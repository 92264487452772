import { CheckOutlined, CloudDownloadOutlined, LoadingOutlined, SyncOutlined, SearchOutlined } from '@ant-design/icons';
import { Badge, Card, Spin, Input, message } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetch } from '../../../hooks/useFetch/useFetch';
import ProjectsAddModal from '../menuAdmin/4_2_projects/ModalAddProject/ProjectsAddModal';
import TableLZM from '../../components/design/table/TableLZM';
import { ButtonLZ } from '../../components/design/Buttons/ButtonLZ';
import { customRequest } from '../../../common/request/customRequest';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { authFetch } from '../../../common/request/authFetch';
import { SCHEDULEDJOB } from '../../../common/constants/services';
import { FixReviews } from './Modal/FixReviews';

export const Services = () => {
  const { t } = useTranslation();
  const [addUserVisible, setAddUserVisible] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  const [projects, setProjects] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSynchronizing, setIsSynchronizing] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  useFetch(`${process.env.REACT_APP_API}/tech/scheduledJobs`, {}, [updateData], (data) => {

    setProjects(data);
    setIsLoading(isLoading);
  });

  const sync = async () => {

    setIsSynchronizing(true);
    await customRequest(`${process.env.REACT_APP_API}/tech/services`, { method: 'POST' })
      .then(() => {
        setUpdateData(!updateData);
        setIsSynchronizing(false);
      })
      .catch(() => {
        setIsSynchronizing(false);
      });
  }

  const replyReviewsAutomated = async () => {
    
    await customRequest(`${process.env.REACT_APP_API}/tech/services/automated`, { method: 'POST' });
  }  

  const launchSchedulePosts = async () => {
    
    await customRequest(`${process.env.REACT_APP_API}/tech/services/schedulePosts`, { method: 'POST' });
  }  

  const launchHistoricReviews = async () => {
    
    await customRequest(`${process.env.REACT_APP_API}/tech/services/historicReviews`, { method: 'POST' });
  }    

  const launchfixGrouoChanges = async () => {
    
    await customRequest(`${process.env.REACT_APP_API}/tech/services/groupChanges`, { method: 'POST' });
  }    

  const columns = [
    {
      title: 'Servicio',
      key: 'service',
      dataIndex: 'service',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
      }: FilterDropdownProps) => {
        return (
          <Input
            autoFocus
            placeholder="Servicio"
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: any) => {
        return record.service.toLowerCase().includes(value.toLowerCase());

      }
    },
    {
      title: 'GBP email',
      key: 'googleEmail',
      dataIndex: 'googleEmail',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
      }: FilterDropdownProps) => {
        return (
          <Input
            autoFocus
            placeholder="Email"
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: any) => {
        return record.googleEmail.toLowerCase().includes(value.toLowerCase());

      }
    },
    {
      title: 'Sync',
      key: 'sync',
      dataIndex: 'sync',
      render: (text: string, record: any) => (record.sync ?
        <div><SyncOutlined /></div>
        :
        <div><CheckOutlined /></div>
      ),
    },
    {
      title: 'Error',
      key: 'error',
      dataIndex: 'error',
      render: (text: string, record: any) => (record.error ?
        <Badge color='red' />
        :
        <Badge color='green' />
      ),
      sorter: (a: any, b: any) => {
        if (a.error && !b.error) {
          return 1;
        } else if (!a.error && b.error) {
          return -1;
        } else {
          return 0;
        }
      },
    },
    {
      title: 'Manual',
      key: 'manual',
      dataIndex: 'manual',
      render: (text: string, record: any) => (record.manual ?
        <small>Manual</small>
        :
        <small>Automated</small>
      )
    },
    {
      title: 'Última sync',
      key: 'minutesSinceSync',
      dataIndex: 'minutesSinceSync',
      sorter: (a: any, b: any) => b.minutesSinceSync - a.minutesSinceSync,

    },
    {
      title: 'Duración',
      key: 'duration',
      dataIndex: 'duration',
      sorter: (a: any, b: any) => b.duration - a.duration,

    }
  ]

  const notificationTest = async () => {
    await authFetch(`${process.env.REACT_APP_API}/users/notifications`, { method: 'POST' }).then((res) => {
    });
  }

  const infoExternalWebs = async () => {
    await authFetch(`${process.env.REACT_APP_API}/externalWebsites`, { method: 'POST' }).then((res) => {
    });
  }

  const filteredEmails = projects
    .filter((obj: { service: string; }) => obj.service === SCHEDULEDJOB.GBP_LOCATIONS)
    .map((obj: { googleEmail: any; }) => obj.googleEmail);

  /* Servicios */

  return (
    <>
      <Card className='main-card-content'>
        <h1 className='title-card'>{t('technology.page.services.title')}</h1>
        <div className="buttonWrapper" >
          <ButtonLZ onClick={() => { setOpenModal(true) }}>Reseñas</ButtonLZ>
          <ButtonLZ color='yellow'
            onClick={() => {
              sync();
            }}>
            {!isSynchronizing ? <CloudDownloadOutlined /> : <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />} {t('technology.page.services.buttons.syncAllLocations')}
          </ButtonLZ>
          <ButtonLZ color='yellow'
            onClick={() => {
              replyReviewsAutomated();
            }}>
            {!isSynchronizing ? <CloudDownloadOutlined /> : <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />} {'Contestar reseñas'}
          </ButtonLZ>
          <ButtonLZ color='yellow' onClick={() => { launchSchedulePosts() }}>Posts Programados</ButtonLZ>
          <ButtonLZ color='yellow' onClick={() => { launchHistoricReviews() }}>Histórico de Reseñas</ButtonLZ>
          <ButtonLZ color='yellow' onClick={() => { launchfixGrouoChanges() }}>Fix Grupos</ButtonLZ>          
          <ButtonLZ onClick={() => { notificationTest() }}>Enviar Notificacion</ButtonLZ>
          <ButtonLZ onClick={() => { infoExternalWebs() }}>Web</ButtonLZ>
        </div>
        <TableLZM columns={columns} dataSource={projects} rowKey={'name'} loading={isLoading} />
        <ProjectsAddModal
          visible={addUserVisible}
          onFinish={() => {
            setAddUserVisible(false);
            setUpdateData(!updateData);
          }}
        />
      </Card>

      {openModal && <FixReviews updateData={updateData} setUpdateData={setUpdateData} visible={openModal} onFinish={() => { setOpenModal(false) }} emails={filteredEmails}/>}
    </>
  )
}