
import { AppstoreAddOutlined, AuditOutlined, BankOutlined, ClusterOutlined, DoubleLeftOutlined, DoubleRightOutlined, GoogleOutlined, KeyOutlined, RocketOutlined, SafetyCertificateOutlined, SolutionOutlined, UserOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { Layout as AntdLayout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../store/AuthContext/AuthContext';
import { ConfigRoutes } from '../../../routing/UserRoutes';
import { useIsAuthorized } from '../../pages/common/roles/Roles';

const { Sider, Content } = AntdLayout;
const grey = '#bab6b6';
const black = "#051235";

export const ConfigLayout = () => {

	const isAuthorized = useIsAuthorized();
	const location = useLocation();
  	const pathName = location.pathname;
	const { collapsed, setCollapsed } = useAuth();
	const { t } = useTranslation();		

	return (
		<AntdLayout>			
			<Sider width={140} className='container-space' collapsed={collapsed} collapsible={true} trigger={null}>	
				<aside>
					<nav>
						<ul>	
							<Link to="/configurar/plan">
								<li className={`${(pathName == '/configurar/plan') ? 'item-selected' : 'item-no-selected' }${` item-space`}`}>
									<div>
										<RocketOutlined  style={{color: (pathName == '/configurar/plan') ? black : grey }} className='icon-menu-item'/>
										<span className={`${(pathName == '/configurar/plan') ? 'selected' : 'no-selected' }`}>Plan</span>
									</div>
								</li>
							</Link>
							{isAuthorized(['ROLE_ADMIN', 'ROLE_COMPETITORS']) &&  
								<Link to="/configurar/keywords">
									<li className={`${(pathName == '/configurar/keywords') ? 'item-selected' : 'item-no-selected' }${` item-space`}`}>
										<div>
											<KeyOutlined style={{color: (pathName == '/configurar/keywords') ? black : grey }} className='icon-menu-item'/>
											<span className={`${(pathName == '/configurar/keywords') ? 'selected' : 'no-selected' }`}>{'Keywords'}</span>
										</div>
									</li>
								</Link>			
							}
							<Link to="/configurar/invitados">
								<li className={`${(pathName == '/configurar/invitados') ? 'item-selected' : 'item-no-selected' }${` item-space`}`}>
									<div>
										<UserOutlined style={{color: (pathName == '/configurar/invitados') ? black : grey }} className='icon-menu-item'/>
										<span className={`${(pathName == '/configurar/invitados') ? 'selected' : 'no-selected' }`}>{t('subMenu.setup.users')}</span>
									</div>
								</li>
							</Link>							
							<Link to="/configurar/permisos">
								<li className={`${(pathName == '/configurar/permisos') ? 'item-selected' : 'item-no-selected' }${` item-space`}`}>
									<div>
										<ClusterOutlined style={{color: (pathName == '/configurar/permisos') ? black : grey }} className='icon-menu-item'/>
										<span className={`${(pathName == '/configurar/permisos') ? 'selected' : 'no-selected' }`}>{t('subMenu.setup.groups')}</span>
									</div>
								</li>
							</Link>	
						</ul>
					</nav>
				</aside>
			</Sider>
			
			<Content className='container-space'>
				<ConfigRoutes />				
			</Content>
			
		</AntdLayout>
	)
};
