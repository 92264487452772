import { CheckOutlined, CloudDownloadOutlined, LoadingOutlined, SyncOutlined, SearchOutlined } from '@ant-design/icons';
import { Badge, Card, Spin, Input, message } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetch } from '../../../hooks/useFetch/useFetch';
import ProjectsAddModal from '../menuAdmin/4_2_projects/ModalAddProject/ProjectsAddModal';
import TableLZM from '../../components/design/table/TableLZM';
import { ButtonLZ } from '../../components/design/Buttons/ButtonLZ';
import { customRequest } from '../../../common/request/customRequest';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { authFetch } from '../../../common/request/authFetch';
import { SCHEDULEDJOB } from '../../../common/constants/services';
import moment from 'moment';
import { TableFilter } from '../../../common/utils/FilterUtils';
import Operations from '../common/sideMenu/Logs/Operations';

export const MonitoringJobs = () => {

  const { t } = useTranslation();
  const [addUserVisible, setAddUserVisible] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  const [projects, setProjects] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);  
  const [job, setJob] = useState<any>(null);

  useFetch(`${process.env.REACT_APP_API}/projects/jobs`, {}, [updateData], (data) => {

    setProjects(data);
    setIsLoading(isLoading);
  });

  /*useFetch(`${process.env.REACT_APP_API}/tech/scheduledJobs`, {}, [updateData], (data) => {

    setProjects(data);
    setIsLoading(isLoading);
  });*/

  const columns = [
    {
      title: 'JobID',
      key: 'id',
      dataIndex: '_id',      
      render: (text: string, record: any) => {				
				return <>
        <Badge color={record.numberOfErrors > 0 ? 'red' : 'green'}/>
            <a style={{fontSize:'12px'}} onClick={() => {setJob({id: record._id, action: record.type, operations: record.numberOfOperations, created: record.executed})}}>{record._id}</a>
            </>
			}
    },    
    {
      title: 'project',
      key: 'project',
      dataIndex: 'project',      
      filterDropdown: TableFilter,
      filterIcon: () => {
			  return <SearchOutlined />;
			},  
      onFilter: (value: any, record: any) => record.project.includes(value),
      sorter: (a: any, b: any) => a.project.localeCompare(b.project),
    },
    {
      title: 'type',
      key: 'type',
      dataIndex: 'type',    
      filterDropdown: TableFilter,
      filterIcon: () => {
			  return <SearchOutlined />;
			},  
      onFilter: (value: any, record: any) => record.type.includes(value),
    },
    {
      title: 'numberOfOperations',
      key: 'numberOfOperations',
      dataIndex: 'numberOfOperations',
      sorter: (a: any, b: any) => b.numberOfOperations - a.numberOfOperations
    },
    {
      title: 'numberOfErrors',
      key: 'numberOfErrors',
      dataIndex: 'numberOfErrors',
      sorter: (a: any, b: any) => b.numberOfErrors - a.numberOfErrors
    },
    {
      title: 'executed',
      key: 'executed',
      dataIndex: 'executed',
      render: (text: string, record: any) => {				
				return <div>
          {moment(record.executed).format('lll')}
          </div>
			}
    }
  ]
  

  /* Servicios */

  return (
    <>
      <Card className='main-card-content'>
        <h1 className='title-card'>Errores</h1>
        
        <TableLZM columns={columns} dataSource={projects} rowKey={'name'} loading={isLoading} />
        <ProjectsAddModal
          visible={addUserVisible}
          onFinish={() => {
            setAddUserVisible(false);
            setUpdateData(!updateData);
          }}
        />
      </Card>
      {job && <Operations job={job} onFinish={() => setJob(null)}/>      }
    </>
  )
}