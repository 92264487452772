import { Modal, Form, Input, Select, Tag } from 'antd';
import { authFetch } from '../../../../../common/request/authFetch';
import { useTranslation } from 'react-i18next';
import { ROLES } from '../../../common/roles/Roles';
import { getProjectStorage } from '../../../../../store/storageContext';
import { useState } from 'react';
import { useFetch } from '../../../../../hooks/useFetch/useFetch';

export const AddCreditsPackModal = (props : {project: any, onFinish: any}) => {
    
    const [form] = Form.useForm();
    
	const { t } = useTranslation();    
    
    const onFormFinish = async (values: any) => {
        const data = {
            ...values
        };
        await authFetch(`${process.env.REACT_APP_API}/projects/${props.project.name}/credits/pack`, { method: 'PUT', data: data });        
        props.onFinish();
    };

  return (
    <>
        <Modal 
            open={true} 
            onOk={() => form.submit()} 
            onCancel={props.onFinish} 
            afterClose={form.resetFields}>
            <h2>Añadir paquete de créditos</h2>

            <Form 
                layout='vertical' 
                form={form} 
                onFinish={onFormFinish}
                autoComplete="off">
                <Form.Item 
                    initialValue={props.project.name}
                    label='Nombre' 
                    name='name' >
                    <Input disabled={true} className="disabled" />
                </Form.Item>                
                <Form.Item 
                    label='Pack de créditos' 
                    name='creditsPack' >
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    </>
  )
}