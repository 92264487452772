import { DownloadOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { CSVLink } from 'react-csv';

interface Dato {
  id: number;
  nombre: string;
  valor: number;
}

export const CSVDownload = (props: {dataSource: any, title: string, fileName: string}) => {

  return (
    <div>            
        <CSVLink data={props.dataSource} filename={props.fileName}>
          <DownloadOutlined /> {props.title}
        </CSVLink>
    </div>
  );
}
