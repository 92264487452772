import { useState, useEffect } from 'react';

import GoogleMapReact, { fitBounds } from 'google-map-react';
import { EnvironmentFilled } from "@ant-design/icons";
import { useFetch } from '../../../hooks/useFetch/useFetch';
import { ModalInfo } from './modal/ModalInfo';


const position = [{ name: 'El Punto', address: 'Calle Padilla 170, Barcelona', type: 'secondary', radius: 20, color: 'red' },
{ name: 'El Punto Restaurante Peruano', address: 'Calle Mallorca 107, Barcelona', type: 'secondary', radius: 40, color: 'blue' }];


export const MapsBusiness = () => {

    const [positionsWithCoordinates, setPositionsWithCoordinates] = useState<any>([]);


    const [zoom, setZoom] = useState(10);
    const [center, setCenter] = useState({ lat: 0, lng: 0 });


    
    const { data, isLoading }: any = useFetch(
        `${process.env.REACT_APP_API}/places/rank/?longitude=2.152208838622091&latitude=41.386433800843605&text=restaurants`,
        {},
        [],
    );

   
    useEffect(() => {

        // Con un address encuentra las coordenadas (llamando api google desde frontend). Una query por cada location
        

        const getCoordinates = async (position: any) => {
            const posWithLatLng = [];

            for (const pos of position) {
                try {
                    const response = await fetch(
                        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(pos.address)}&key=AIzaSyCtmjrbwFhUPNP-LfETh_aAKaFuZJhwDm4`
                    );

                    if (!response.ok) {
                        throw new Error('Error al obtener las coordenadas');
                    }

                    const data = await response.json();
                    const { results } = data;

                    if (results.length > 0) {
                        const { lat, lng } = results[0].geometry.location;
                        const positionWithCoordinates = { ...pos, lat, lng };
                        posWithLatLng.push(positionWithCoordinates);
                    } else {
                        throw new Error('No se encontraron resultados de coordenadas para la dirección proporcionada');
                    }
                } catch (error) {
                    console.error(`Error obteniendo coordenadas para ${pos.address}:`, error);
                }
            }
            setPositionsWithCoordinates(posWithLatLng)
        };


        getCoordinates(position);
    }, [position]);
    useEffect(() => {
        if (positionsWithCoordinates.length > 0) {
            // Inicializa las coordenadas de los límites noroeste y sureste
            let minLat = positionsWithCoordinates[0]?.lat;
            let maxLat = positionsWithCoordinates[0]?.lat;
            let minLng = positionsWithCoordinates[0]?.lng;
            let maxLng = positionsWithCoordinates[0]?.lng;


            positionsWithCoordinates.map((marker: { lat: number; lng: number; }) => {
                minLat = Math.min(minLat, marker.lat);
                maxLat = Math.max(maxLat, marker.lat);
                minLng = Math.min(minLng, marker.lng);
                maxLng = Math.max(maxLng, marker.lng);


            });

            //Obtiene los bounds para posicionar el mapa

            const neswBounds: any = {
                nw: { lat: minLat, lng: minLng },
                se: { lat: maxLat, lng: maxLng }
            };


            const size = {
                width: 200, // Ancho del mapa en píxeles
                height: 200, // Alto del mapa en píxeles
            };

            const { center, zoom } = fitBounds(neswBounds, size);

            setCenter(center)
            setZoom(zoom)
        }
    }, [positionsWithCoordinates])

    const [openModal, setOpenModal] = useState({ open: false, idLoc: 0 })
    const [pos, setPos] = useState<any>()



    const handleMarkerClick = (id: number, pos: any) => {
        setPos(pos)
        setOpenModal({ open: true, idLoc: id })
    }


    const Marker = ({ lat, lng, color, onClick, radius }: any) => (
        <div style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
            {/* Círculo */}
            <div style={{ position: 'absolute',transform: 'translate(-50%, -50%)', width: radius * 2, height: radius * 2, borderRadius: '50%', background: color, opacity: 0.2 }}></div>
            {/* Marcador */}
            <div style={{ position: 'absolute', transform: 'translate(-50%, -50%)' }} onClick={onClick}>
                <EnvironmentFilled style={{ fontSize: '20px', color }} />
            </div>
        </div>
    );


    function handleApiLoaded(map: any, maps: any): void {

        // Te permite acceder a otros objetos de la api de google (Como Circle, aunque ahora se está haciendo con css)

    }

    return (
        <div style={{ width: '600px', height: '600px', borderRadius: '25px', overflow: 'hidden', position: 'relative' }}>
            <div style={{ height: '100%', width: '100%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: "AIzaSyCtmjrbwFhUPNP-LfETh_aAKaFuZJhwDm4" }}
                    defaultCenter={{ lat: 0, lng: 0 }}
                    center={center}
                    defaultZoom={10}
                    zoom={zoom}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }: any) => handleApiLoaded(map, maps)}
                >
                    {positionsWithCoordinates.map((pos: any, index: any) => (
                        <Marker
                            key={index}
                            lat={pos.lat}
                            lng={pos.lng}
                            color={pos.color}
                            radius={pos.radius}
                            onClick={() => handleMarkerClick(index, pos)}

                        />

                    ))}

                    {/* a cualquier componente dentro de GoogleMapReact se le tiene que pasar lat y lng para que lo posicione en el mapa */}
                    {openModal.open &&
                        <ModalInfo
                            lat={pos?.lat}
                            lng={pos?.lng}
                            pos={pos}
                            openModal={openModal}
                            setOpenModal={setOpenModal} />}


                </GoogleMapReact>


            </div>
        </div>
    );
}