import { useTranslation } from 'react-i18next';
import { Period } from '../components/Period';

export const FestiveScheduleView = (props: {
	data: any
}) => {
	
	const { t } = useTranslation();

	return (
		<div>
			{props.data[0]?.closed ? 
                        <b style={{color:'grey'}}>Cerrado</b>
                        :
                        props.data.map((period: any, i: number) => (                    
                            <Period data={period} key={i} />
                        ))
			}
		</div>
	)
}

export const FestiveScheduleSmall = (props: {
	data: any
}) => {
	
	const { t } = useTranslation();

	return (
		<>
			{props.data[0]?.closed ? 
                        <p style={{color:'grey', margin: '5px'}}>Cerrado</p>
                        :
						<div style={{display: "flex", alignItems:'flex-end'}}>
							{props.data.map((period: any, i: number) => (                    
								<div style={{marginLeft:'6px'}}><Period data={period} key={i} /></div>
							))}
						</div>
			}
		</>
	)
}
