import React, { useState } from 'react'
import { Geogrid } from '../Geogrid'
import { Badge, Drawer, Rate, Tag } from "antd";
import { AimOutlined, EnvironmentFilled, InfoCircleFilled, ShopFilled, ShopTwoTone } from '@ant-design/icons';
import { CaretIncrement } from '../../../../common/utils/Increment';
import { getColor } from '../../menuInfo/pageSEO/LocalSEO';

export const Marker = ({ lat, lng, location, zoom, onClick }: any) => {

    //const [circleVisible, setCircleVisible] = useState(false);
    const [panelVisible, setPanelVisible] = useState(false);

    const handleMouseEnter = () => {
        setPanelVisible(true);                
    };
    
    const handleMouseLeave = () => {
        setPanelVisible(false);                
    };

    return (
        <div style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', zIndex: panelVisible ? 100 : 1}}>
            
            {location.reference ?
                <div style={{ position: 'absolute', transform: 'translate(-50%, -50%)', width: location.localPackMaxDistance * 0.01 * Math.pow(2, (zoom + 1) - 10), height: location.localPackMaxDistance * 0.01 * Math.pow(2, (zoom + 1) - 10), borderRadius: '50%', background: 'blue', opacity: 0.1 }}></div>
                :
                panelVisible && <div style={{ position: 'absolute', transform: 'translate(-50%, -50%)', width: location.localPackMaxDistance * 0.01 * Math.pow(2, (zoom + 1) - 10), height: location.localPackMaxDistance * 0.01 * Math.pow(2, (zoom + 1) - 10), borderRadius: '50%', background: 'teal', opacity: 0.2 }}></div>
            }
            
            <div style={{ position: 'absolute', transform: 'translate(-50%, -50%)'}} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={onClick}> 
                <Badge size="small" count={location.rankPosition} color={getColor(location.distanceDiff)}>
                {location.reference ?
                    <EnvironmentFilled style={{ fontSize: '26px', color: 'blue', cursor: 'pointer'}} /> 
                    :
                    <EnvironmentFilled style={{ fontSize: '26px', color: 'black', cursor: 'pointer'}} /> 
                }
                </Badge>
            </div>  

            <div>
                <div>
                    {panelVisible && 
                        <div style={{background:'rgba(0, 0, 0, 0.75)', borderRadius:'10px', color: 'white', position:'absolute', width:'250px', margin:'5px', padding:'10px'}}>
                            <div><b>{location.name}</b></div>                                                    
                            <Rate value={location.rating} style={{fontSize:'14px', marginTop:'5px'}} allowHalf/>  
                            <span style={{marginLeft:'15px'}}>{location.userRatingsTotal} reseñas</span>
                            <div style={{fontSize:'14px', marginTop:'5px'}}>
                                <Tag style={{borderRadius:'10px'}} color={getColor(location.distanceDiff)}><b><b>Posición Media:</b> {location.popularityPosAVG.toFixed(1)} <CaretIncrement value={location.distanceDiff} numberOfDecimals={1} color={'white'}/></b></Tag>  
                                <div style={{marginTop:'15px'}}><b>Localpack: </b></div>
                                <div style={{marginLeft:'15px'}}>- Presencia {(100 * location.localPackPercent).toFixed(0)}%</div>
                                <div style={{marginLeft:'15px'}}>- Alcance: {location.localPackMaxDistance.toFixed(1)} mts.</div>
                            </div>
                    </div>}                    
                </div>
            </div>
        </div>        
    )
}
