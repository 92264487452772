import { Alert, Form, InputNumber, Modal, Radio, Spin, Tag } from 'antd';
import 'moment/locale/es';
import { useState } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useAuth } from '../../../../store/AuthContext/AuthContext';
import { authFetch } from '../../../../common/request/authFetch';
import { getProjectStorage } from '../../../../store/storageContext';
import { customRequest } from '../../../../common/request/customRequest';
import { STATUS_CODE } from '../../../../common/constants/statusCode';

export const NewGeogridModal = (props: {locationId: string, keyword: string, onFinish: any, onClose: any}) => {
	
	const [form] = Form.useForm();
	const [isLoading, setIsLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState(false);

	const onFormFinish = async (values: any) => {
		
		const data = {
			...values,
			keyword: props.keyword
        };

		setIsLoading(true);
		const response = await customRequest(`${process.env.REACT_APP_API}/projects/${getProjectStorage()}/geogrid/${props.locationId}`, { method: 'POST', data: data });
		
		if (response?.statusCode === STATUS_CODE.BAD_REQUEST) {			
			setErrorMessage(response.message)			
		} else {
			props.onFinish();
		}
	
		setIsLoading(false);
	
	}			 
	
	return (

		<Modal open={true} onOk={() => form.submit()} onCancel={props.onClose} afterClose={form.resetFields}>

			<h2>Nuevo escaneo</h2>			
			<span>Palabra Clave </span> <Tag style={{padding:'3px 8px', margin:'5px', fontSize:'14px'}} color='Teal'> {props.keyword}</Tag>
			<br/><br/>			
			<Form layout='horizontal' form={form} onFinish={onFormFinish} initialValues={{distance:500, grid:2}}>
				
				<Form.Item
					rules={[{ required: true, message:'Por favor, ingrese la distance en metros' }]}					
					name='distance'
					label='Distancia'>		        
            		<InputNumber controls={true} addonAfter="mts" min={500} max={100000} step={100} />
				</Form.Item>

				<Form.Item name='grid' label="Coordenadas">
					<Radio.Group>
						<Radio value={2}> 5x5 </Radio>
						<Radio value={3}> 7x7 </Radio>
						<Radio value={4}> 9x9 </Radio>
						<Radio value={5}> 11x11 </Radio>
						<Radio value={6}> 13x13 </Radio>
					</Radio.Group>
				</Form.Item>
			</Form>			
			<div style={{display:'flex', justifyContent:'center'}}><Spin size='large' spinning={isLoading}/></div>
			{errorMessage && <b><Alert type='warning' showIcon={true} message='No hay créditos disponibles'/></b>}
		</Modal>
	)
}
