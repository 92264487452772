import { Col, Form, Select, Tag } from "antd"
import { useFetch } from "../../../../hooks/useFetch/useFetch";
import { useState } from "react";

export default function SelectPeriod(props: {setSelectedType: any}) {
    
    return (
        <Select  bordered={false} 
            defaultValue='ALL' 
            style={{ minWidth: '180px', color:'steelblue', fontWeight: 'bold', fontSize: '16px', textAlign:'right' }}             
            onChange={(value:any) => {props.setSelectedType(value)}} >
            <Select.Option value='CURRENT_MONTH'>Mes Actual</Select.Option>
            <Select.Option value='PREVIOUS_MONTH'>Mes Anterior</Select.Option>
            <Select.Option value='LAST_7DAYS'>Últimos 7 días</Select.Option>
            <Select.Option value='LAST_30DAYS'>Últimos 30 días</Select.Option>
            <Select.Option value='LAST_3MONTHS'>Últimos 3 meses</Select.Option>
            <Select.Option value='LAST_12MONTHS'>Últimos 12 meses</Select.Option>
            <Select.Option value='ALL'>Desde el Inicio</Select.Option>
        </Select>
    )
}
