import { Card, Col, Divider, Rate, Row, Tag } from "antd";
import { RingProgress } from "@ant-design/charts";
import { ShopOutlined, StarFilled, StarOutlined } from "@ant-design/icons";
import MathUtils from "../../../../common/utils/MathUtils";
import { useTranslation } from "react-i18next";
import reviewsIcon from '../../../../assets/icons/REVIEWS-ICON-light.png';
import { Increment } from "../../../../common/utils/Increment";
import { ColorStar } from "../../../../common/utils/ColorStar";

export const BlockReviewsKpis = (props: {reviewsKPIs: any, period: string, recentPeriod: string}) => {

    const { t } = useTranslation();

    return (
        <>
          <Row gutter={36} style={{ marginBottom: '20px' }}>
                <Col span={12}>
                    <Card bodyStyle={{ backgroundColor: 'white', borderRadius: '25px', height: '180px' }}>
                        <div className="card-kpis">
                            <h1></h1>
                            <Tag color='steelblue' style={{borderRadius:'10px'}}>{PERIOD[props.period]}</Tag>
                        </div>
                        <div style={{display:'flex', alignItems:'center', justifyContent:'space-around'}}>
                            {props.reviewsKPIs?.rateMin && 
                                <div style={{marginTop:'6px'}}>                            
                                    Mínimo
                                    <ColorStar style={{ fontSize: '18px' }} value={props.reviewsKPIs.rateMin}/>								
                                </div>
                            }
                            <div className="rating-container">
                                <img src={reviewsIcon} style={{height:'60px', borderRadius: '17px'}}/>                                    
                                <span >
                                    {props.reviewsKPIs?.rateAvg === null ? '-' : MathUtils.round(props.reviewsKPIs?.rateAvg, 1)}
                                </span>                                
                            </div>
                            {props.reviewsKPIs?.rateMax && 
                                <div style={{marginTop:'6px'}}>                            
                                    Máximo
                                    <ColorStar style={{ fontSize: '18px' }} value={props.reviewsKPIs.rateMax}/>								
                                </div>
                            }
                        </div>
                        <div style={{display:'flex', justifyContent:'center'}}>
                                <div>
                                    <b>{props.reviewsKPIs.numberOfReviews} reseñas</b>
                                    {/*<Increment numberOfDecimals={2} value={props.reviewsKPIs.rateAvgIncrement}/>*/}
                                </div>                            				
                        </div>                        
                    </Card>
                </Col>
                <Col span={12}>
                    <Card bodyStyle={{ backgroundColor: 'white', color:'steelblue', borderRadius: '25px', height: '180px' }}>
                        <div className="card-kpis">
                            <h1></h1>
                            <Tag color='blue' style={{borderRadius:'10px'}}>{PERIOD[props.recentPeriod]}</Tag>
                        </div>
                        <div className="values-kpis" style={{justifyContent:'space-around'}}>
                            <span>
                            {props.reviewsKPIs.numberOfRecentReviews > 0 && <span><StarFilled style={{fontSize:'28px', paddingRight:'10px', color:'LightSteelBlue'}}/>{MathUtils.round(props.reviewsKPIs.recentRateAvg, 1)}</span>}
                            </span>  
                            <span>
                                {props.reviewsKPIs.numberOfRecentReviews > 0 ?
                                <span>{MathUtils.round(100 * props.reviewsKPIs.recentRepliedRatio, 1)} %</span>
                                :
                                <>-</>}
                            </span>                            
                        </div>
                        <div style={{display:'flex', justifyContent:'space-around'}}>
                            <span style={{color:'steelblue'}}><b>{props.reviewsKPIs?.numberOfRecentReviews} reseñas</b></span>  
                            <span style={{color:'steelblue'}}>%Respuesta</span>  
                        </div>
                    </Card>
                </Col>                    
            </Row>                   
        </>
    )
}

export const PERIOD: any = {
    "LAST_7DAYS": "Últimos 7 días",  
    "LAST_30DAYS": "Últimos 30 días",  
    "CURRENT_MONTH": "Mes Actual",  
    "PREVIOUS_MONTH": "Mes Anterior", 
    "LAST_3MONTHS": "Últimos 3 meses", 
    "LAST_12MONTHS": "Últimos 12 meses", 
    "ALL": "Desde el Inicio"
}  