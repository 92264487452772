import { EditOutlined } from '@ant-design/icons'
import { useState, useContext } from 'react'
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { RegularWeekScheduleMassiveEdit } from './bulk/RegularWeekScheduleMassiveEdit';
import { RegularWeekScheduleMassiveEditLV } from './bulk/RegularWeekScheduleMassiveEditLV';
import { RegularWeekScheduleMassiveEditLS } from './bulk/RegularWeekScheduleMassiveEditLS';
import { RegularWeekScheduleMassiveEditLJ } from './bulk/RegularWeekScheduleMassiveEditLJ';


export const NormalScheduleMassive = (props: { locationsIds: string[], onFinish:any, onCancel:any }) => {
    
    const [edit, setEdit] = useState(false);
    const [editLJ, setEditLJ] = useState(false);
    const [editLV, setEditLV] = useState(false);
    const [editLS, setEditLS] = useState(false);

	const { t } = useTranslation();    
	
return (
    <div>    		   
		   <div>{t('dictionary.schedule')}</div>
            { (!editLJ && !editLV && !editLS ) && 
                <div style={{ textDecoration: "none", fontSize: "16px", color: "#3C4043", margin: "15px", cursor: 'pointer' }}>        
                    {<Tag style={{borderRadius:'10px'}} onClick={() => setEdit(true)}><EditOutlined /> Editar <b>Día a día</b></Tag>}
                </div>
            }
            { (!edit && !editLV && !editLS ) && 
                <div style={{ textDecoration: "none", fontSize: "16px", color: "#3C4043", margin: "15px", cursor: 'pointer' }}>                    
                    {<Tag style={{borderRadius:'10px'}} onClick={() => setEditLJ(true)}><EditOutlined /> Editar con <b>Lunes a Jueves</b> en bloque</Tag>}
                </div>
            }
            { (!edit && !editLJ && !editLS ) && 
                <div style={{ textDecoration: "none", fontSize: "16px", color: "#3C4043", margin: "15px", cursor: 'pointer' }}>        
                    {<Tag style={{borderRadius:'10px'}} onClick={() => setEditLV(true)}><EditOutlined /> Editar con <b>Lunes a Viernes</b> en bloque</Tag>}
                </div>
            }
            { (!edit && !editLJ && !editLV ) && 
                <div style={{ textDecoration: "none", fontSize: "16px", color: "#3C4043", margin: "15px", cursor: 'pointer' }}>        
                    {<Tag style={{borderRadius:'10px'}} onClick={() => setEditLS(true)}><EditOutlined /> Editar con <b>Lunes a Sábado</b> en bloque</Tag>}
                </div>
            }

			{edit && <RegularWeekScheduleMassiveEdit 
					locationsIds={props.locationsIds} 
					onFinish={() => {setEdit(false)}}/>}

			{editLJ && <RegularWeekScheduleMassiveEditLJ 
					locationsIds={props.locationsIds} 
					onFinish={() => {setEditLJ(false)}}/>}

			{editLV && <RegularWeekScheduleMassiveEditLV 
					locationsIds={props.locationsIds} 
					onFinish={() => {setEditLV(false)}}/>}

			{editLS && <RegularWeekScheduleMassiveEditLS 
					locationsIds={props.locationsIds} 
					onFinish={() => {setEditLS(false)}}/>}
    </div>
)
}