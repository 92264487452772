import { Alert, Button, Card, DatePicker, Divider, Form, message, Modal, Switch, TimePicker } from "antd";

import { useTranslation } from 'react-i18next';
import { getProjectStorage } from "../../../../../../store/storageContext";
import { useAuth } from "../../../../../../store/AuthContext/AuthContext";
import { useContext, useState } from "react";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import { GlobalVariableContext } from "../../../../../../store/GlobalVariableContext/GlobalVariableContext";
import { customRequest } from "../../../../../../common/request/customRequest";
import { STATUS_CODE } from "../../../../../../common/constants/statusCode";

export const FestiveMassiveRemove = (props: {locationsIds: string[], onFinish: any}) => {

	dayjs.extend(customParseFormat);

	const [shiftForm] = Form.useForm();
	const {updateGlobalData, setUpdateGlobalData } = useContext(GlobalVariableContext)
	const { groupAccount } = useAuth();

	const { t } = useTranslation();

	const [errorMessage, setErrorMessage] = useState<any>(null);

	const submitRemoveFestive = async (values: any) => {		

		const data = {
			field: 'REMOVE_SPECIAL_HOURS',
			specialHours: {								
				festiveDate: values.festiveDate
			},			
			locationsNames: props.locationsIds
		};

		
		const response = await customRequest(`${process.env.REACT_APP_API}/projects/${getProjectStorage()}/${groupAccount}/locations`, { method: 'PATCH', data: data });
		
		if (response?.statusCode === STATUS_CODE.BAD_REQUEST) {
			setErrorMessage(response.message);			
		} else if (response?.statusCode === STATUS_CODE.OK) {
			props.onFinish();
			setUpdateGlobalData(!updateGlobalData);
		} 
	};

	return (

		<Form layout='vertical' form={shiftForm} onFinish={(values) => submitRemoveFestive(values)}>
			<Form.Item name='festiveDate' rules={[{ required: true, message: 'Fecha del festivo requerida' }]}>
				<DatePicker size='small' format='DD-MM-YYYY'	/>				
			</Form.Item>					
			<Form.Item shouldUpdate style={{ marginTop: '15px' }}>
				{() => {
					return (
						<>
							<Button
								style={{ background: 'var(--primary)', color: 'white', marginLeft: '20px' }}									
								onClick={
									() => {
										Modal.confirm({
											title: t('view.forms.modal.single.title'),
											okText: t('dictionary.confirm'),
											cancelText: t('dictionary.cancel'),
											onOk: shiftForm.submit
										});
									}
								}>
								{t('dictionary.save')}
							</Button>
							<Button
								style={{ background: 'white', color: 'dodgerblue', marginLeft: '20px', borderColor: 'dodgerblue' }}									
								onClick={() => {
									shiftForm.resetFields();																						
									props.onFinish();
								}}
								type="primary">
								{t('dictionary.cancel')}
							</Button>
						</>
					)
				}}
			</Form.Item>				
			{errorMessage && <Alert
					type='error'
					message={errorMessage}
					banner
				/>
				}					
		</Form>
	)
}