import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { CloseOutlined, DeleteOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { FestiveScheduleView } from './FestiveScheduleView';
import { FestiveScheduleEdit } from './FestiveScheduleEdit';
import { useIsAuthorized } from '../../../../../pages/common/roles/Roles';
import { Modal } from 'antd';
import { customRequest } from '../../../../../../common/request/customRequest';
import { getProjectStorage } from '../../../../../../store/storageContext';
import GroupAccount from '../../../../../pages/register/GroupAccount';
import { STATUS_CODE } from '../../../../../../common/constants/statusCode';
import { GlobalVariableContext } from '../../../../../../store/GlobalVariableContext/GlobalVariableContext';
import { useAuth } from '../../../../../../store/AuthContext/AuthContext';

export const FestiveSchedule = (props: { locationName: string, date: any, periods: any }) => {
    
    const { t } = useTranslation();
    const isAuthorized = useIsAuthorized();
    const [edit, setEdit] = useState(false);    
    const {updateGlobalData, setUpdateGlobalData } = useContext(GlobalVariableContext);
	const { groupAccount } = useAuth();

	const [errorMessage, setErrorMessage] = useState<any>(null);

    const submitRemoveFestive = async () => {		

		const data = {
			field: 'REMOVE_SPECIAL_HOURS',
			specialHours: {								
				festiveDate: props.date
			},			
			locationsNames: props.locationName
		};
		
		const response = await customRequest(`${process.env.REACT_APP_API}/projects/${getProjectStorage()}/${groupAccount}/${props.locationName}`, { method: 'PATCH', data: data });
		
		if (response?.statusCode === STATUS_CODE.BAD_REQUEST) {
			setErrorMessage(response.message);			
		} else if (response?.statusCode === STATUS_CODE.OK) {			
			setUpdateGlobalData(!updateGlobalData);
		} 
	};

    return (
        <div >
            <div style={{ display: "flex", justifyContent: 'space-between' }}>
                <p style={{ color: 'black' }}>
                    {moment(props.date).format('ll')} 
                </p>
                <div style={{ display: "flex", justifyContent: 'space-between' }}>
                    {edit ?
                        <CloseOutlined style={{color:'grey'}} onClick={() => setEdit(!edit)}/>
                        :
                        isAuthorized(['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_CLIENT', 'ROLE_GUEST_INFO_EDIT']) && <EditOutlined style={{color:'grey'}} onClick={() => setEdit(!edit)}/>
                    }
                    {                        
                        !edit && isAuthorized(['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_CLIENT', 'ROLE_GUEST_INFO_EDIT']) && 
                        <DeleteOutlined style={{color:'grey', paddingLeft:'8px'}} onClick={
                            () => {
                                Modal.confirm({
                                    title: 'Confirma el borrado?',
                                    okText: t('dictionary.confirm'),
                                    cancelText: t('dictionary.cancel'),
                                    onOk: submitRemoveFestive
                                });
                            }
                        }/>
                    }
                </div>
            </div>
            
            <div style={{marginLeft:'15px'}}>
                {!edit ?                     
                    <FestiveScheduleView data={props.periods}/>                    
                    :
                    <FestiveScheduleEdit 
                        locationName={props.locationName}
                        festiveDate={props.date} 
                        ranges={props.periods} 
                        onCancel={() => {
                            setEdit(false);
                        }}
                        onFinish={() => {
                            setEdit(false);
                        }}/>                        
                }
            </div>
        </div>
    )
}