import { EditOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { useState, useContext } from 'react'
import { Tag, Button, Divider, Form, message, Modal, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useAuth } from '../../../../../store/AuthContext/AuthContext';
import { DrawerContext } from '../../../../../store/DrawerContext/DrawerContext';
import { authFetch } from '../../../../../common/request/authFetch';
import { getProjectStorage } from '../../../../../store/storageContext';
import { FestiveMassiveAdd } from './bulk/FestiveMassiveAdd';
import { FestiveMassiveRemove } from './bulk/FestiveMassiveRemove';


export const SpecialHoursMassive = (props: { locationsIds: string[], onFinish:any, onCancel:any }) => {
    
    const [add, setAdd] = useState(false);
	const [remove, setRemove] = useState(false);

	const { t } = useTranslation();
	
	return (
		<div>				
			<div style={{display:'flex'}}>
				<div style={{ textDecoration: "none", fontSize: "16px", color: "#3C4043", margin: "15px", cursor: 'pointer' }}>        
					{!remove && <Tag style={{borderRadius:'10px', color:'darkgreen'}} onClick={() => setAdd(true)}><b><PlusOutlined /> Añadir festivo</b></Tag>}
				</div>

				<div style={{ textDecoration: "none", fontSize: "16px", color: "#3C4043", margin: "15px", cursor: 'pointer' }}>        
					{!add && <Tag style={{borderRadius:'10px', color:'brown'}} onClick={() => setRemove(true)}><b><MinusOutlined /> Borrar festivo</b></Tag>}
				</div>
			</div>

			{add && 			
				<FestiveMassiveAdd
					locationsIds={props.locationsIds} 
					onFinish={() => {setAdd(false)}}/>
			}

			{remove && 
				<FestiveMassiveRemove 
					locationsIds={props.locationsIds} 
					onFinish={() => {setRemove(false)}}/>
			}		
		</div>
	)
}