import { Period, PeriodReduced } from "../../location/hours/components/Period"


export const RegularDayScheduleView = (props: { title: any, regularHour:any }) => {

    return (
        <div style={{ display: "flex", justifyContent:'space-between'}}>
            <p style={{ width: "150px" , color:'#666', textAlign:'start'}} >
                {props.title}
            </p>
            <div style={{display: "flex", flexDirection:'column', alignItems:'flex-end', marginTop:'10px'}}>
                {
                    props.regularHour.length === 0
                        ? 
                            <b style={{color:'grey'}}>Cerrado</b>
                        : 
                        props.regularHour.map((data: any, i: any) => (
                                <Period data={data} key={i} />
                            ))
                }
            </div>
        </div>

    )
}

export const RegularDayScheduleViewSmall = (props: { title: any, regularHour:any }) => {

    return (
        <div style={{ display: "flex", justifyContent:'space-between'}}>
            {props.regularHour.length !== 0 && <div style={{ width: "100px" , color:'#666', textAlign:'end', margin:'0px'}} >
                {props.title}
            </div>}
            <span>
                {
                    props.regularHour.length !== 0 &&                        
                        props.regularHour.map((data: any, i: any) => (
                                <PeriodReduced data={data} key={i} />
                            ))
                }
            </span>
        </div>

    )
}
