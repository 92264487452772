import {Card} from "antd";

export const ModalGeogrid = ({places, openModal,setOpenModal}:any) => {
    
    console.log(places)
    return(
        <Card style={{position:'relative',margin:'10px', boxSizing:'border-box', height:'97%', backgroundColor:'#fff', borderRadius:10}}>
            <div style={{display:'flex', justifyContent:'space-between', alignItems:'center',width:'100%', height:'60px', boxShadow:'0px 5px 10px #ccc', padding:'0 20px'}}>
                <div></div>
                <p style={{margin:0}}>Titulo</p>
                <strong style={{cursor:'pointer'}} onClick={()=>setOpenModal(false)}>X</strong>
            </div>
            
            {
                places?.map((place:any)=>{
                    return <div style={{display:'flex', alignItems:'baseline', gap:'10px'}}>
                       <small>{place.visibilityScore}</small> <h3>{place.name}</h3>
                    </div>
                })
            }
        </Card>
    )
}