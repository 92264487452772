import { Card, Col, Progress, Row, Statistic, Tag, Tooltip } from "antd"
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getProjectStorage } from "../../../../store/storageContext";
import { authRequest } from "../../../../common/request/authRequest";
import { RingProgress } from "@ant-design/charts";
import MathUtils from "../../../../common/utils/MathUtils";
import { InfoCircleOutlined } from "@ant-design/icons";
import rocketIcon from '../../../../assets/rocketIcon.png';

export const Plan = () => {

    const [project, setProject] = useState<any>(null);    
    const { t } = useTranslation();
    
	useEffect(() => {
        
        authRequest(`${process.env.REACT_APP_API}/projects/` + getProjectStorage() + '/credits',
            { method: 'GET' }
            )
            .then((data) => {                
                setProject(data);                                                    
            })
            
        }, []); 
		
    return (
        <>
        <Card className='main-card-content'>
            
            <h1 className='title-card'>Plan</h1>
            <br/>
			<h2 style={{color:'black'}}>Créditos para mapas de competidores</h2>
            
			<Row gutter={20} style={{ marginBottom: '20px' }}>                        
						<Col span={9}>
                            <Card bodyStyle={{ backgroundColor: 'white', borderRadius: '25px', height: '150px' }}>
                                <div className="card-kpis">
                                    <h1>Plan Actual</h1>                                    
                                </div>
                                <div className="totalPost-container" >
                                    {/*<img src={calendarIcon} style={{height:'60px', borderRadius: '17px'}}/>*/}
									<img src={rocketIcon} style={{height:'60px', borderRadius: '17px'}}/>
                                    <h1 style={{fontSize:'20px', border:'solid 2px purple', color:'purple', padding:'6px 10px', borderRadius:'15px'}}>
										{project?.creditsPlan?.name}
                                    </h1>
                                </div>
                            </Card>
                        </Col> 
						<Col span={5}>
                            <Card bodyStyle={{ backgroundColor: 'white', borderRadius: '25px', height: '150px' }}>
                                <div className="card-kpis">
                                    <h1>#Créditos disponibles</h1>
                                </div>
                                <div className="totalPost-container" >
                                    {/*<img src={calendarIcon} style={{height:'60px', borderRadius: '17px'}}/>*/}
                                    <span>
                                        {project?.creditsPlan?.credits}										
                                    </span>
                                </div>
                            </Card>
                        </Col>
						<Col span={5}>
                            <Card bodyStyle={{ backgroundColor: 'white', borderRadius: '25px', height: '150px' }}>
                                <div className="card-kpis">
                                    <h1>Créditos/mes</h1>
                                </div>
                                <div className="totalPost-container" >
                                    {/*<img src={calendarIcon} style={{height:'60px', borderRadius: '17px'}}/>*/}
                                    <span>
                                        {project?.creditsPlan?.creditsPerMonth}										
                                    </span>
                                </div>
                            </Card>
                        </Col>		
						<Col span={5}>
                            <Card bodyStyle={{ backgroundColor: 'white', borderRadius: '25px', height: '150px' }}>
                                <div className="card-kpis">
                                    <h1>Consumo de créditos</h1>
                                </div>
                                <div className="totalPost-container" >
                                    <span>
									<Progress  width={80} type='dashboard' strokeWidth={14} strokeColor={'purple'} trailColor={'lightgrey'} percent={Math.round(100 - 100 * project?.creditsPlan?.credits / project?.creditsPlan?.creditsPerMonth)} />														
                                    </span>
                                </div>
                            </Card>
                        </Col>				                     
                    </Row>
			<div>                        
				
			</div>			
        </Card>    
        </>
    )
}
    
const configProgress: any = {
	height: 75,
	width: 75,
	autoFit: false,
	color: ['#5B8FF9', '#E8EDF3'],
};