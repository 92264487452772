import { t } from 'i18next';
import moment from 'moment';
import { FestiveSchedule } from './single/FestiveSchedule';
import { useState } from 'react';
import { FestiveMassiveAdd } from './bulk/FestiveMassiveAdd';
import { Card, Tag } from 'antd';
import { PlusOutlined } from '@ant-design/icons';


export const SpecialSchedule = (props: { location: any }) => {

    const [add, setAdd] = useState(false);

    return (
        <div>            
            <span>
                <b className="title-box">Festivos</b>
            </span>

            <div style={{ margin: "1em" }}>
                
                <div style={{ textDecoration: "none", fontSize: "16px", color: "#3C4043", margin: "15px", cursor: 'pointer' }}>        
					{<Tag style={{borderRadius:'10px', color:'darkgreen'}} onClick={() => setAdd(true)}><b><PlusOutlined /> Añadir festivo</b></Tag>}
                    {add && 			
                        <Card>
                            <FestiveMassiveAdd
                            locationsIds={[props.location?.name]} 
                            onFinish={() => {setAdd(false)}}/>
                        </Card>
                    }
                </div>                
                {!add && props.location.specialHours && Object.entries(props.location.specialHours).map(([date, periods]) => {
                    return (
                        <div key={date}>
                            <div style={{ display: "flex", flexDirection: 'column', justifyContent: "flex-start", marginTop:'10px' }}>                                                                        
                                <FestiveSchedule date={date} periods={periods} locationName={props.location.name}/>                                                                            
                            </div>
                        </div>
                    );
                })}
            </div>    
                       
        </div >
    );
};