import React, { useState } from 'react'
import { Geogrid } from '../Geogrid'
import { Badge, Drawer, Rate, Tag } from "antd";
import { AimOutlined, DislikeFilled, EnvironmentFilled, FireFilled, InfoCircleFilled, LikeFilled, LikeOutlined, QuestionCircleFilled, ShopFilled, TrophyFilled, TrophyOutlined } from '@ant-design/icons';
import { GeogridPointDrawer } from './GeogridPointDrawer';

export const MarkerGeoGrid = ({ lat, lng, point }: any) => {
    
    
    const [panelVisible, setPanelVisible] = useState(false);
    const [geogridPointDrawer, setGeogridPointDrawer] = useState(null);
    
    const handleMouseEnter = () => {
        setPanelVisible(true);                
    };
    
    const handleMouseLeave = () => {
        setPanelVisible(false);                
    };

    const onClick = () => {
        setGeogridPointDrawer(point);   
    }

    return (
        <>
        <div style={{ position: 'absolute', transform: 'translate(-50%, -50%)', zIndex: panelVisible ? 100 : 1 }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={onClick} >
            {point.mainPlace ? 
                <Badge size="small" count={point.mainPlace.positionDiff} color={point.mainPlace.positionDiff < 0 ? 'DarkRed': 'darkgreen'}> 
                    <div style={{ backgroundColor: DOT_COLOR[point.mainPlace.positionPopularity], cursor: 'pointer', color:'white', height: '38px', width: '38px', borderRadius: '50%', border:'solid 2px white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <b>{point.mainPlace.positionPopularity}</b>
                    </div>
                </Badge>
                :            
                <div style={{ backgroundColor: DOT_COLOR[21], cursor: 'pointer', color:'white', height: '38px', width: '38px', borderRadius: '50%', border:'solid 2px white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <b>{'+20'}</b>
                </div>
            }
            <div>
            {panelVisible && 
                <div style={{background:'white', borderRadius:'10px', color: 'black', position:'absolute', width:'250px', margin:'5px', padding:'10px', border:'solid grey 1px'}}>                    
                    <b>LOCALPACK</b>
                    <br/><br/>
                    {point?.places.slice(0, 3).map((place: any, index: any) => (
                        <div style={{display:'flex'}} key={index}>
                            <div style={{ backgroundColor: place.reference ? 'black' : DOT_COLOR[index + 1], color:'white', height: '20px', width: '20px', borderRadius: '50%', border:'solid 2px white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                {index + 1}                    
                            </div>
                            <div style={{marginLeft:'5px'}}>{place.name}</div>
                        </div>
                    ))}                                                                     
                </div>}                    
            </div>
        </div>
            {geogridPointDrawer && <GeogridPointDrawer point={point} onFinish={() => {setGeogridPointDrawer(null);}}/>}	
            </>									
    )
}

export const DOT_COLOR: any = {
    "1": "LimeGreen",  
    "2": "LimeGreen",  
    "3": "LimeGreen",  
    "4": "darkorange", 
    "5": "darkorange", 
    "6": "darkorange", 
    "7": "darkorange", 
    "8": "darkorange", 
    "9": "darkorange", 
    "10": "darkorange",
    "11": "crimson", 
    "12": "crimson", 
    "13": "crimson", 
    "14": "crimson", 
    "15": "crimson", 
    "16": "crimson", 
    "17": "crimson", 
    "18": "crimson", 
    "19": "crimson", 
    "20": "crimson", 
    "21": "silver"    
}
