import React from 'react'
import { Geogrid } from '../Geogrid'
import { Drawer } from "antd";

export const ModalInfo = ({pos, openModal,setOpenModal}:any) => {

    console.log(pos)
    return(
        <Drawer
        placement="right"
        width={'45%'}
        visible={openModal}
        onClose={() => setOpenModal(false)}
        bodyStyle={{ background: '#F4F7FA' }}
        title={pos.name}
       
    >
            {/* <div style={{position:'relative',margin:'10px', boxSizing:'border-box', width:'360px', backgroundColor:'#fff', borderRadius:10}}> */}
            {/* <div style={{display:'flex', justifyContent:'space-between', alignItems:'center',width:'100%', height:'60px', boxShadow:'0px 5px 10px #ccc', padding:'0 20px'}}>
                <div></div>
                <p style={{margin:0}}>{pos.name}</p>
                <strong style={{cursor:'pointer'}} onClick={()=>setOpenModal({open:false, idLoc:0})}>X</strong>
            </div> */}
            <div style={{padding:'1em'}}>
                <p>{pos.address}</p>
                <Geogrid lat={pos.lat} lng={pos.lng}/>
            </div>
        {/* </div> */}
        </Drawer>
    )
}