import { AlertOutlined, CalendarFilled, CalendarOutlined, CheckCircleTwoTone, GoogleCircleFilled, GoogleOutlined, PhoneOutlined, ShopFilled, ShopOutlined, WarningTwoTone } from "@ant-design/icons"
import { Badge, Button, Card, Tag, Tooltip } from "antd"
import { STATUS } from "./OpenForBusiness"
import { VERIFY_STATUS, VERIFY_STATUS_COLOR } from "./VerifyStatus"
import { RegularDayScheduleViewSmall } from "./forms/RegularDayScheduleView"
import { FestiveSchedule } from "../location/hours/single/FestiveSchedule"
import { FestiveScheduleSmall, FestiveScheduleView } from "../location/hours/single/FestiveScheduleView"
import moment from "moment"

export const Location = (props: {record: any, onChange?: any}) => {

	return (
		<div style={{background:'white', borderRadius:'10px', padding :'10px', display:'flex', justifyContent:'space-between'}}>
			<div style={{display:'flex'}}>
				<Tooltip title={VERIFY_STATUS[props.record.status]}>					
					<Badge status={VERIFY_STATUS_COLOR[props.record.status]}/>
				</Tooltip>
				<div>
					<span style={{fontWeight: 600, fontSize: "15px", paddingRight:'10px'}}> {props.record.title}</span><br/>
					<AddressGroup record={props.record}/>
					{ props.record.openForBusiness?.status?.includes('CLOSED') && <div style = {{color:'red'}}>{STATUS[props.record.openForBusiness?.status]}</div>}					
				</div>
			</div>
			
			{props.record.metadata?.mapsUri && <Button onClick={() => {props.onChange()}} size='small' style={{ borderRadius: '20px', background:'white',  border:`solid 1.3px RoyalBlue`, color: "RoyalBlue" }}>    
				<small><a target={'_blank'} href={props.record.metadata.mapsUri}><GoogleOutlined/> Ver en Google</a></small>
			</Button>}
		</div>		
	)
}

export const LocationCardCompacted = (props: {location: any, onChange?: any}) => {

	return (
		<div style={{background:'white', borderRadius:'10px', maxWidth:'300px', border:'1px solid rgb(230, 240, 230)', padding :'10px'}}>
			
			<span style={{fontWeight:400}}>{props.location?.title}</span>
			<div>
				<small>
					{props.location && <> {props.location.addressLine ? 
						<i style={{color:'grey'}}>{`${props.location?.addressLine}, ${props.location?.locality}, ${props.location?.administrativeArea}`}</i>
						: 
						<i style={{color:'grey'}}>Sin ubicación</i>}</>
					}
				</small>
			</div>
			
		</div>
	)
}

export const LocationTitle = (props: {location: any}) => {

	return (
		<>
			<span>{props.location?.title}</span>
			<span>
				<small>
					{props.location && <> {props.location.addressLine ? 
						<i style={{color:'grey'}}>{`${props.location?.addressLine}, ${props.location?.locality}, ${props.location?.administrativeArea}`}</i>
						: 
						<i style={{color:'grey'}}>Sin ubicación</i>}</>
					}
				</small>
			</span>
        </>
	)
}

export const Categories = (props: {categories: any}) => {

	return (		
		<div>
            <ul>
                <li>
                    <b style={{ color: "#666" }}>
                        {props.categories.primaryCategory.displayName}
                    </b>
                </li>
                {
                    props.categories.additionalCategories && props.categories.additionalCategories.map((additionalCategory: any, i: number) => (
                        <li style={{ color: "#666" }} key={i}>
                            {additionalCategory.displayName}
                        </li>
                    ))
                }
            </ul>
        </div>		
	)
}

export const NameGroup = (props: {record: any}) => {

	return (
		<>			
			<span style={{paddingRight:'10px'}}>{props.record.title}</span><br/>
			<small style={{color:'grey'}}>{props.record.categories?.primaryCategory?.displayName}</small>
		</>
	)
}

export const StatusGroup = (props: {record: any}) => {

	return (
		<p>
			{props.record.status == 'HAS_VOICE_OF_MERCHANT' ?<CheckCircleTwoTone twoToneColor="#52c41a" /> : <WarningTwoTone twoToneColor="#eb2f96" />}<br/>
		</p>
	)
}

export const AddressGroup = (props: {record: any}) => {
	
	return (
	  (props.record.locality !== null && props.record.administrativeArea !== null) ? (
		<>
		  <span style={{ fontWeight: 600, fontSize: "12px", color:'#808080' }}>{props.record.locality},</span>
		  <i style={{ fontWeight: 600, fontSize: "12px", color:'#808080' }}> {props.record.administrativeArea}<br /></i>
		  {props.record.addressLine !== null && (
			<small style={{ color: '#808080', fontSize: "12px" }}>
			  {props.record.addressLine}
			</small>
		  )}
		</>
	  ) : (
		<small style={{ color: '#808080', fontSize: "12px" }}>Sin ubicación</small>
	  )
	);
  };
  
export const StoreCodeGroup = (props: {record: any}) => {

  	return (
		<>
			{
				props.record.storeCode ?
					props.record.storeCode : "-"
			}
			{
				props.record.todayPosts && 
					<CheckCircleTwoTone/>
			} 
		</>
	)
}

export const ContactGroup = (props: {record: any}) => {
		
		return (
			<>
				<a>{props.record.websiteUri}</a><br/>
				<span style={{marginRight:"1em"}}><PhoneOutlined/> {props.record.primaryPhone}</span>
				{ props.record.additionalPhones &&
				props.record.additionalPhones.map((additional:string, i:number)=>(
					<span style={{marginRight:"1em"}}><PhoneOutlined/> {additional}</span>
				))}
			</>
	  )
}

export const Hours = (props: {location: any}) => {
		
	return (
		<>
			<small>                        
				{props.location?.regularHours && <RegularDayScheduleViewSmall title="Lunes" regularHour={props.location?.regularHours![0]} />}
				{props.location?.regularHours && <RegularDayScheduleViewSmall title="Martes" regularHour={props.location?.regularHours![1]} />}
				{props.location?.regularHours && <RegularDayScheduleViewSmall title="Miércoles" regularHour={props.location?.regularHours![2]} />}
				{props.location?.regularHours && <RegularDayScheduleViewSmall title="Jueves" regularHour={props.location?.regularHours![3]} />}
				{props.location?.regularHours && <RegularDayScheduleViewSmall title="Viernes" regularHour={props.location?.regularHours![4]} />}
				{props.location?.regularHours && <RegularDayScheduleViewSmall title="Sábado" regularHour={props.location?.regularHours![5]} />}
				{props.location?.regularHours && <RegularDayScheduleViewSmall title="Domingo" regularHour={props.location?.regularHours![6]} />}				
			</small>  
		</>
  )
}

export const Festives = (props: {specialHours: any}) => {
		
	return (
		<>
			{props && props.specialHours && Object.entries(props.specialHours).map(([key, periods]) => (			
				<small style={{display:'flex', justifyContent:'space-between', alignItems:'center', color: "#666"}}>
					<div>                        					
						<CalendarOutlined/>	{moment(key).format('ll')}
					</div>  
					<div>   
						<FestiveScheduleSmall data={periods}/> 
					</div>  
				</small>  
			))}
		</>
  )
}

// *********************** WORKING ON ******************
