import { Button, Form, Modal, Input } from 'antd'
import { useTranslation } from 'react-i18next';
import GoogleMapReact, { fitBounds } from 'google-map-react';
import { authFetch } from '../../../common/request/authFetch';
import { authRequest } from '../../../common/request/authRequest';
import { useState, useEffect } from 'react';
import { ModalGeogrid } from './modal/ModalInfoGeogrid';



export const Geogrid = ({lat, lng}:any) => {


    const [form] = Form.useForm();
    const { t } = useTranslation();

    const [openModal, setOpenModal] = useState(false)
    const [pos, setPos] = useState<any>()

    const [data, setData] = useState<any>();
    const [center, setCenter] = useState<any>();
    const [zoom, setZoom] = useState<any>();

    useEffect(() => {
        if (data?.length > 0) {
            // Inicializa las coordenadas de los límites noroeste y sureste
            let minLat = data[0]?.lat;
            let maxLat = data[0]?.lat;
            let minLng = data[0]?.lng;
            let maxLng = data[0]?.lng;


            data?.map((marker: { lat: number; lng: number; }) => {
                minLat = Math.min(minLat, marker.lat);
                maxLat = Math.max(maxLat, marker.lat);
                minLng = Math.min(minLng, marker.lng);
                maxLng = Math.max(maxLng, marker.lng);


            });

            //Obtiene los bounds para posicionar el mapa

            const neswBounds: any = {
                nw: { lat: minLat, lng: minLng },
                se: { lat: maxLat, lng: maxLng }
            };


            const size = {
                width: 700, // Ancho del mapa en píxeles
                height: 600, // Alto del mapa en píxeles
            };

            const { center, zoom } = fitBounds(neswBounds, size);

            setCenter(center)
            setZoom(zoom)
        }
    }, [data])


    const onFinish = async (values: any) => {

        await authRequest(
            `${process.env.REACT_APP_API}/geogrid`,
            { method: 'POST', data: values }
        ).then((res) => {
            setData(res?.coordinates);
        })

    }


    const handleMarkerClick = (pos: any) => {
        setPos(pos)
        setOpenModal(true)
    }

    const Marker = ({ lat, lng, color, position, onClick }: any) => (

        <div style={{ position: 'absolute', transform: 'translate(-50%, -50%)' }} onClick={onClick} >
            <div style={{ backgroundColor: color, height: '30px', width: '30px', borderRadius: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {position}
            </div>
        </div>
    );
    return (
        <div style={{ display: 'flex', gap: '50px' }}>

            <div style={{ width: '700px', height: '600px', borderRadius: '25px', overflow: 'hidden', position: 'relative' }}>
                <div style={{ height: '100%', width: '100%' }}>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: "AIzaSyCtmjrbwFhUPNP-LfETh_aAKaFuZJhwDm4" }}
                        defaultCenter={{ lat: 40.463667, lng: -3.74922 }}
                        center={center}
                        defaultZoom={10}
                        zoom={zoom}

                    >
                        {data?.map((pos: any, index: any) => (
                            <Marker
                                key={index}
                                lat={pos.lat}
                                lng={pos.lng}
                                //Hacer un mapa con los colores y la puntuación >> colors[pos.position]
                                color={"red"}
                                position={pos.position}
                                onClick={() => handleMarkerClick(pos)}

                            />


                        ))}                    
                    </GoogleMapReact>
                </div>
            </div>

            <Form
                layout='vertical'
                form={form}
                onFinish={onFinish}>
                {/* <Form.Item rules={[{ required: true }]} name="latitude" label={'Latitud'} style={{ width: '220px' }}>
                <Input/>
            </Form.Item>
            <Form.Item rules={[{ required: true }]} name="longitude" label={'Longitud'} style={{ width: '220px' }}>
                <Input/>
            </Form.Item> */}
                <Form.Item rules={[{ required: true }]} name="locationId" label={'Id del local'} style={{ width: '220px' }}>
                    <Input />
                </Form.Item>
                <Form.Item rules={[{ required: true }]} name="text" label={'Categoría'} style={{ width: '220px' }}>
                    <Input />
                </Form.Item>
                <Form.Item rules={[{ required: true }]} name="radius" label={'Distancia'} style={{ width: '220px' }}>
                    <Input />
                </Form.Item>
                <div>
                    <Button
                        //   disabled={
                        //     !!categoryForm.getFieldsError().filter(({ errors }:any) => errors.length).length
                        //     || enabled !== fieldId}
                        onClick={form.submit}
                        type="primary">
                        {t('dictionary.save')}
                    </Button>

                </div>
            </Form>


            {openModal &&
                            <ModalGeogrid
                                lat={pos?.lat}
                                lng={pos?.lng}
                                places={pos.places}
                                openModal={openModal}
                                setOpenModal={setOpenModal} />}

        </div>
    )
};
