import { Alert, Button, Card, DatePicker, Divider, Form, message, Modal, Switch, TimePicker } from "antd";

import { useTranslation } from 'react-i18next';
import { getProjectStorage } from "../../../../../../store/storageContext";
import { useAuth } from "../../../../../../store/AuthContext/AuthContext";
import { useContext, useState } from "react";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import { GlobalVariableContext } from "../../../../../../store/GlobalVariableContext/GlobalVariableContext";
import { customRequest } from "../../../../../../common/request/customRequest";
import { STATUS_CODE } from "../../../../../../common/constants/statusCode";

export const FestiveMassiveAdd = (props: {locationsIds: string[], onFinish: any}) => {

	dayjs.extend(customParseFormat);

	const [shiftForm] = Form.useForm();
	const [isOpen, setIsOpen] = useState(true);
	const {updateGlobalData, setUpdateGlobalData } = useContext(GlobalVariableContext)
	const { groupAccount } = useAuth();

	const { t } = useTranslation();

	const format = 'HH:mm';
	const formatTZ = 'YYYY-MM-DDTHH:mm[Z]';	
	const [errorMessage, setErrorMessage] = useState<any>(null);

	const submitRemoveFestive = async (values: any) => {
		
		if (values.openCheckbox && values.festives?.length === 0) {
			return message.warning('Se necesita un rango horario');
		}

		const timeRanges = values.festives && values.festives.map((timeRange: any) => ({openTime: timeRange[0].format(formatTZ), closeTime: timeRange[1].format(formatTZ)}));

		const data = {
			field: 'ADD_SPECIAL_HOURS',
			specialHours: {
				timeRanges : timeRanges,
				open: values.openCheckbox,
				festiveDate: values.festiveDate
			},			
			locationsNames: props.locationsIds
		};

		
		const response = await customRequest(`${process.env.REACT_APP_API}/projects/${getProjectStorage()}/${groupAccount}/locations`, { method: 'PATCH', data: data });
		
		if (response?.statusCode === STATUS_CODE.BAD_REQUEST) {
			setErrorMessage(response.message);			
		} else if (response?.statusCode === STATUS_CODE.OK) {
			props.onFinish();
			setUpdateGlobalData(!updateGlobalData);
		} 
	};

	return (

		<Form layout='vertical' form={shiftForm} onFinish={(values) => submitRemoveFestive(values)} initialValues={{openCheckbox: true}}>
			<Form.Item name='festiveDate' rules={[{ required: true, message: 'Fecha del festivo requerida' }]}>
				<DatePicker size='small' format='DD-MM-YYYY'	/>				
			</Form.Item>		
			
			<Form.Item name={'openCheckbox'} valuePropName="checked" style={{ width: '10%', marginBottom: '10px' }}>
				<Switch size='small' checkedChildren="Abierto" unCheckedChildren="Cerrado" onChange={(e) => {setIsOpen(e)}}/>
			</Form.Item>

			{isOpen && 
				<Form.List name="festives">
					{(fields, { add, remove }) => {
						return (
						<Card style={{background:'GhostWhite'}}>						
							{
							fields.map((field, i) => (
								<div key={field.key}>
								{
									<div style={{ display: 'flex' }}>
										<Form.Item name={[i]} shouldUpdate>					
											<TimePicker.RangePicker  format={format} disabled={!isOpen} minuteStep={10}/>
										</Form.Item>												
										{
										fields.length > 0 && 
											<button
												style={{ width: '10%', height: '32px', backgroundColor: "transparent", border: "none", fontWeight: 600, padding: "0 1em", cursor: "pointer" }}
												onClick={() => remove(field.name)} >
												X
											</button>
										}
									</div>
								}
								</div>
							))
							}
							{(fields.length <= 10) &&
							<button type='button' style={{ paddingBottom: "1em", border: "none", backgroundColor: "transparent", color: "#2781DD", fontWeight: 600, cursor: "pointer" }} onClick={() => add()}>
								+ Añadir Rango Horario
							</button>}
						</Card>
						)
					}}
				</Form.List>
			}						
			<Form.Item shouldUpdate style={{ marginTop: '15px' }}>
				{() => {
					return (
						<>
							<Button
								style={{ background: 'var(--primary)', color: 'white', marginLeft: '20px' }}									
								onClick={
									() => {
										Modal.confirm({
											title: t('view.forms.modal.single.title'),
											okText: t('dictionary.confirm'),
											cancelText: t('dictionary.cancel'),
											onOk: shiftForm.submit
										});
									}
								}
							>
								{t('dictionary.save')}
							</Button>
							<Button
								style={{ background: 'white', color: 'dodgerblue', marginLeft: '20px', borderColor: 'dodgerblue' }}									
								onClick={() => {
									shiftForm.resetFields();																						
									props.onFinish();
								}}
								type="primary">
								{t('dictionary.cancel')}
							</Button>
						</>
					)
				}}
			</Form.Item>				
			{errorMessage && <Alert
					type='error'
					message={errorMessage}
					banner
				/>
				}					
		</Form>
	)
}