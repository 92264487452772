import { Card, Divider, Drawer, Input, Progress, Table, Tag, Tooltip, message } from "antd";
import TableLZM from "../../../components/design/table/TableLZM";
import { AimOutlined, AlertOutlined, CaretDownOutlined, FireFilled, InfoCircleFilled, PushpinOutlined } from "@ant-design/icons";
import { CaretIncrement } from "../../../../common/utils/Increment";
import { DOT_COLOR } from "./MarkerGeoGrid";
import { getColor } from "../../menuInfo/pageSEO/LocalSEO";

export const GeogridPointDrawer = (props: { point: any, onFinish: any }) => {                          

    return (
        <Drawer
            placement="right"
            width={'50%'}
            open={true}
            onClose={props.onFinish}
            bodyStyle={{ background: '#F4F7FA' }}            
            headerStyle={{ background: '#F4F7FA' }}            
            title={<div style={{display:'flex', justifyContent:'space-between'}}>
                <h2 style={{color:'slategray'}}><PushpinOutlined/> Coordenada fijada</h2>                
                <b><AimOutlined/> Lat: {props.point.lat.toFixed(4)} Lng: {props.point.lng.toFixed(4)}</b>
            </div>}>   

            <h2 style={{color:'slategray'}}><b>Negocios ordenados por popularidad</b></h2>
            <Card style={{position:'relative',margin:'10px', boxSizing:'border-box', height:'97%', backgroundColor:'transparent', borderRadius:10}}>                                            

            
                <div style={{display:'flex', color:'slategray', justifyContent:'space-between'}}>
                        
                    <TableLZM
                        rowKey='rankPosition'
                        size='small'
                        //scroll={{ x: true }}
                        pagination={{ pageSize: 100 }}
                        columns={SEO_COLUMNS}
                        dataSource={props.point.places}
                        //loading={isLoading}
                    />
                </div>                    
            </Card>

        </Drawer>    
    );
};

const SEO_COLUMNS: any = [		
    {
        title: '',
        dataIndex: 'positionPopularity',
        render: (text: string, record: any) => (				
                <div style={{ backgroundColor: DOT_COLOR[record.positionPopularity], color:'white', height: '35px', width: '35px', borderRadius: '50%', border:'solid 2px white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {record.positionPopularity < 21 ? record.positionPopularity : <small>{'+20'}</small>}                    
                </div>
        )	
    },           
    {
        title: 'Diff',
        width: '100px',
        align:'center',
        dataIndex: 'positionDiff',
        render: (text: string, record: any) => (                
                <Tag style={{borderRadius:'10px', fontSize:'10px'}} color={getColor(record.positionDiff)}><CaretIncrement value={record.positionDiff} numberOfDecimals={1} color={'white'}/></Tag>                              
        )	
    },    
    {
        title: 'Posición',
        dataIndex: 'positionDistance',
        render: (text: string, record: any) => (
            <>
                <div><small><b>{record.positionPopularity < 21 ? record.positionPopularity : '> 20'}</b> por popularidad</small></div>
                <div style={{color:'Teal'}}><small><b>{record.positionDistance < 21 ? record.positionDistance: '> 20'}</b> por distancia {'('}a {record.distanceToPoint.toFixed(1)} mts.{')'}</small></div>
                
            </>
        )	
    },
    {
        title: 'Nombre',
        dataIndex: 'name',
        width: '200px',
        render: (text: string, record: any) => (	
            <>
            {record.reference?
                <Tag style={{color:'blue', borderRadius:'20px'}} color="dodgerblue"><a href={record.googleMapsUri} target='blank'> {record.name}</a></Tag>
                :
                <a href={record.googleMapsUri} target='blank'> {record.name}</a>
            }
            </>			
        )	
    },
    {
        title: 'Reseñas',
        dataIndex: 'rating',
        align: 'center',
        render: (text: string, record: any) => (
            <div style={{color:'grey'}}>
                <div>{Math.round(10 * record.rating) / 10}</div>
                <small>{record.userRatingsTotal} reseñas</small>
            </div>
        )	
    },        
    {
        title: 'Web',
        dataIndex: 'websiteUri',
        align: 'center',
        render: (text: string, record: any) => (
            <>{record.websiteUri && <a href={record.websiteUri} target='blank'>Web</a>}</>				
        )	
    }         
]