import { CaretDownOutlined, CaretUpOutlined, PlusCircleOutlined, PlusOutlined, StarFilled } from "@ant-design/icons";

export const Increment = (props: {value: any, numberOfDecimals: number}) => {
    return (
        <b>        
            {props.value == 0 ?  
                <span  style={{ marginRight: '8px' }}>
                    <CaretUpOutlined />{`${props.value.toFixed(props.numberOfDecimals)}`}
                </span>   
                :
                props.value > 0 ?
                    <span  style={{ color: 'green', marginRight: '8px' }}>
                        + {`${props.value.toFixed(props.numberOfDecimals)}`}
                    </span> 
                    :
                    <span  style={{ color: 'red', marginRight: '8px' }}>
                        {`${props.value.toFixed(props.numberOfDecimals)}`}
                    </span>                 
            }
        </b> 
    )
};

export const CaretIncrement = (props: {value: any, numberOfDecimals: number, color?: any}) => {
    return (
        <b>        
            {props.value == 0 ?  
                <span  style={{ margin: '0px 6px' }}>
                    -
                </span>   
                :
                props.value > 0 ?
                    <span  style={{ color: props.color ? props.color : 'darkgreen', marginRight: '5px'}}>
                        <CaretUpOutlined />{`${props.value.toFixed(props.numberOfDecimals)}`}
                    </span> 
                    :
                    <span  style={{ color: props.color ? props.color : 'darkred', marginRight: '5px'}}>
                        <CaretDownOutlined />{`${props.value.toFixed(props.numberOfDecimals)}`}
                    </span>                 
            }
        </b> 
    )
};


