import { Form, Select, Modal, Switch, Drawer } from 'antd';
import { authFetch } from '../../../../common/request/authFetch';
import { useFetch } from '../../../../hooks/useFetch/useFetch';
import { ColorStar } from '../../../../common/utils/ColorStar';
import { useState } from 'react';
import TableLZM from '../../../components/design/table/TableLZM';


export const EmailProjectDrawer = (props: {
    onFinish: any,
    onClose: any,
    emailProject: any
}) => {

    const [reviewsInfo, setReviewsInfo] = useState(null);

    useFetch(`${process.env.REACT_APP_API}/tech/services/projects/` + props.emailProject.project + '/email/' + props.emailProject.email, {}, [],    
    (data: any) => {
        setReviewsInfo(data);        
    });

    const columns: any = [		
        {
			title: 'Grupo',
			key: 'accountName',			
			align: 'center',
			//sorter: (a: any, b: any) => b.reviewsKPIs?.numberOfReviews - a.reviewsKPIs?.numberOfReviews,
			render: (text: string, record: any) => (
                <>
                    {record.accountName}
                </>
				)			
		},
        {
			title: 'Local',
			key: 'title',			
			align: 'center',
			//sorter: (a: any, b: any) => b.reviewsKPIs?.numberOfReviews - a.reviewsKPIs?.numberOfReviews,
			render: (text: string, record: any) => (
                <>
                    <div>{record.title}</div>
                    <a href={record.locationLink} target='blank'>Link</a>
                </>
				)			
		},
		{
			title: 'Puntuación',
			key: 'rateAvg',
			dataIndex: 'rateAvg',
			width: '20px',
			align: 'center',
			//sorter: (a: any, b: any) => b.reviewsKPIs?.rateAvg - a.reviewsKPIs?.rateAvg,
			render: (text: string, record: any) => (record.reviewsInfo?.avgStarRating &&
                <>
                    <div><ColorStar
                        style={{ fontSize: '18px' }}
                        value={Math.round(10 * record.reviewsInfo.avgStarRating) / 10}/>
                    </div>
                    <div>{record.reviewsInfo.numberOfReviews} reseñas</div>    					
				</>)			
		}		
    ]

    return (

        <Drawer
            placement="right"
            width={'50%'}
            open={true}            
            onClose={props.onClose}
            bodyStyle={{ background: '#F4F7FA' }}
            title={<h2 style={{color:'slategray'}}><b>Locales con reseñas</b></h2>}>   

            
            {reviewsInfo &&						
							<TableLZM 
								//rowKey='reviewsInfo._id'
								scroll={{ x: true }}						
								columns={columns}
								dataSource={reviewsInfo}
								loading={false}/>
						}	

        </Drawer>
    )
}

