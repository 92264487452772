import { Form, Select, Modal, Switch } from 'antd';
import { authFetch } from '../../../../common/request/authFetch';



export const FixReviews = ({
    visible,
    onFinish,
    emails,
    setUpdateData,
    updateData
}: any) => {

    const [form] = Form.useForm();

    const onFormFinish = async (values: any) => {
        await authFetch(`${process.env.REACT_APP_API}/tech/services/syncLocation?client=${values.client}&isPartial=${values.isPartial}` , { method: 'POST' })
        .then(() => { setUpdateData(!updateData) })
        onFinish();
    }

    return (

        <Modal open={visible} onOk={() => form.submit()} onCancel={onFinish} afterClose={form.resetFields}>
            <h1>Sincronizar un local</h1>
            <Form layout='vertical' form={form} onFinish={onFormFinish} initialValues={{ isPartial: true }}>
                <Form.Item name='client' label='Cliente' rules={[{ required: true }]} >
                   <Select>
                        {
                            emails.map((element: any) =>
                                <Select.Option value={element}>{element}</Select.Option>
                            )}
                    </Select>
                </Form.Item>

                <Form.Item name={'isPartial'} style={{ width: '10%', marginBottom: '10px' }}>
					<Switch style={{width:'125px'}}  checkedChildren="Última semana" unCheckedChildren="Todo" defaultChecked={true}/>
				</Form.Item>
            </Form>

        </Modal>
    )
}

