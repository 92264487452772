import { Tag } from 'antd';

export const Terms = (props: { terms: any, icon: any}) => {
	
	return (
		<>													                
			{ props.terms?.map((term: any, index: any) => {
				return <Term term={term} icon={props.icon} key={index}/>
			})
			}        			     
		</>
	)
}

export const Term = (props: { term: any, icon: any }) => {
	
	return (
		<Tag icon={props.icon} style={{padding:'3px 8px', margin:'5px', fontSize:'14px'}} color='Teal'> {props.term.name}</Tag>            
	)
}
